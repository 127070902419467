import React from 'react';
import classNames from 'classnames';
import { Col} from "reactstrap";
import { SectionProps } from "./../utils/SectionProps";
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import background from "./../../../assets/img/block1.jpg";
const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  // const [videoModalActive, setVideomodalactive] = useState(false);
  //
  // const openModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(true);
  // }
  //
  // const closeModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(false);
  // }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div style={{ borderSyle:'none',
                    marginTop:'50px',marginBottom:'50px',
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    backgroundPosition:'center',
                    backgroundRepeat:'no-repeat'}}className="container-sm">
        <div className={innerClasses}>
        <Col sm="6" md="6" lg="6">
          <div className="hero-content">
            <h2 className="text-color-primary" data-reveal-delay="200">
            An IoT Solution for <br/> Data-Driven Decisions
            </h2>
            <ButtonGroup>
               <Button tag="a" color="primary" wideMobile href="/contact/">
                Request A Demo
                </Button>
            </ButtonGroup>

          </div>
          </Col>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
