import React from "react";

import { Auth } from 'aws-amplify';
import * as FileSaver from "file-saver";
import { Button, Row, Col, Form, FormGroup, Input, Label } from "reactstrap";
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";

const SensorTypes = [{name: "temperature", title: "Temperature Sensor"},
                     {name: "location",    title: "Location Sensor"},
                     {name: "pressure",    title: "Pressure Sensor"},
                     {name: "other",       title: "Other"}]

const API_URL = "http://localhost:8000/device/";

class NewDeviceForm extends React.Component {
  state = {
    pk: 0,
    device_list: [],
    username:"",
    start_date: new Date().toISOString().slice(0,10),
    end_date: new Date().toISOString().slice(0,10),
    export_format:"Excel"
  };

  async componentDidMount() {
      const userInfo = await Auth.currentUserInfo();
    this.setState({
         username: userInfo.attributes.email
       });
     }


  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  exportData = e => {
    e.preventDefault();
    axios.get(API_URL+"export", {responseType: 'blob', params: { user_id: this.state.username,
                                           device_list: this.state.device_list,
                                           start_date: this.state.start_date,
                                           end_date: this.state.end_date,
                                           export_format:this.state.export_format}},
                                 )
    .then((res) => {
      const filename = "data"+"_"+this.state.start_date+"_"+this.state.end_date+"_"+".xls";
      let file = new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"})
      FileSaver.saveAs(file, filename)})
  };

  defaultIfEmpty = value => {
    return value === "" ? "" : value;
  };

  render() {
    const SensorList = this.props.devices;
    return (
      <Form onSubmit={this.exportData}>
          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label for="device_list">Device List:</Label>
                <Input
                  type="select"
                  name="device_list"
                  onChange={this.onChange}
                  value={this.defaultIfEmpty(this.state.device_list)}
                >
                <option>Select a device...</option>
                {
                  SensorList.map( m => (
                    <option value={m.sensor_id}>{m.title}</option>
                  ))
                }
                </Input>
              </FormGroup>
            </Col>
            <Col md={4}>
            <FormGroup>
              <Label for="start_date">Start Date:</Label>
                  <Input
                    type="date"
                    name="start_date"
                    onChange={this.onChange}
                    value={this.state.start_date}
                  />
              </FormGroup>
            </Col>
            <Col md={4}>
            <FormGroup>
              <Label for="end_date">End Date:</Label>
                  <Input
                    type="date"
                    name="end_date"
                    onChange={this.onChange}
                    value={this.state.end_date}
                  />
              </FormGroup>
            </Col>
        </Row>
        <Row form>
        <Col md={4}>
           <FormGroup>
                <Label for="export_format">Export Format:</Label>
                <Input
                  type="select"
                  name="export_format"
                  id="export_format"
                  onChange={this.onChange}
                  value={this.defaultIfEmpty(this.state.export_format)}
                >
                <option>Select format...</option>
                <option>Excel</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Button className="float-right btn-fill" size="sm" color="info">Export</Button>
      </Form>

    );
  }
}

export default NewDeviceForm;
