/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

import PageHeader from "../components/PageHeader";


// reactstrap components

// I need to fix the this.setstate. This context is lost.
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import NotificationList from "../components/NotificationList";
import { Auth } from 'aws-amplify';
import axios from "axios";

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'


const API_URL = "/device/"

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username:'',
      notifications:[],
      loading: true,
      checkedBoxes: []
    };
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  async componentDidMount() {

    const userInfo = await Auth.currentUserInfo();

    this.setState({
         username: userInfo.attributes.email
       });
       this.resetState();
     }

  async onClick (event){
    event.preventDefault();
    this.setState({ loading:true })
    await axios.delete(API_URL +"delete_notifications/",{ data: { IDs: this.state.checkedBoxes }})
    .then(resp =>  this.resetState() );
    this.setState({ checkedBoxes: [], loading:false });
  }

  getNotifications = () => {
    axios.get(API_URL+"notifications/", {params: { user_id: this.state.username }})
     .then(response => this.setState({notifications: response.data, loading:false}));
   };

   toggleCheckbox = (e, notification)=> {
     if(e.target.checked) {
        let arr = this.state.checkedBoxes;
         arr.push(notification.pk);
          this.setState({ checkedBoxes: arr});
  } else {
      let items = this.state.checkedBoxes.splice(this.state.checkedBoxes.indexOf(notification.pk), 1)
    this.setState({ checkedBoxes: items})
  }};


     resetState = ()=> {
       this.getNotifications();
     };
  render() {
    return (
      <>
      <div className="content">

      <PageHeader/>
      <Container style={{ marginTop: "20px" }}>
        <Row>
          <Col>
            <NotificationList
              loading={this.state.loading}
              notifications={this.state.notifications}
              resetState={this.resetState}
              toggleCheckbox={this.toggleCheckbox}
              onClick={this.onClick}
            />
          </Col>
        </Row>
      </Container>
      </div>
      </>
    );
  }
}

export default Notifications;
