import React from 'react';
// import sections
// import Hero from '../components/HomePage/sections/Hero';
// import FeaturesTiles from '../components/HomePage/sections/FeaturesTiles';
// import FeaturesSplit from '../components/HomePage/sections/FeaturesSplit';
// import Testimonial from '../components/HomePage/sections/Testimonial';
import AboutUs from '../components/HomePage/sections/AboutUs';
// import Cta from '../components/HomePage/sections/Cta';
import Main from '../components/Layout/Main';
const AboutPage = () => {

  return (
    <>
      <Main title={"NexGen IoT — About Us"}/>
      <AboutUs />
    </>
  );
}

export default AboutPage;
