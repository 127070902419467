/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ec849e4a-83fc-48be-8c27-5eca21705406",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_oyWA5BYJH",
    "aws_user_pools_web_client_id": "171s7k6jlrgrbrj469e5h19gsq",
    "oauth": {
        "domain": "delta989bc2f5-989bc2f5-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://localhost:8000/dashboard/main/",
        "redirectSignOut": "https://localhost:8000/",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://mbea25qnjrgc5kr2qvzdg5vzse.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
