import React, { Component } from "react";
import { Card, CardHeader,  CardBody, CardTitle, Table  } from "reactstrap";
import { Spin } from "antd";
import NewDeviceModal from "../components/NewDeviceModal.js";

import ConfirmRemovalModal from "../components/ConfirmRemovalModal.js";

class DeviceList extends Component {


  render() {
    const devices = this.props.devices;
    const link = "add/";
    return (
  <>
  <Card>
    <CardHeader>
      <CardTitle tag="h6">Device List</CardTitle>
    </CardHeader>
    <CardBody>
     { this.props.loading ? (
       <div style={{textAlign:'center'}}>
        <Spin tip="Loading..." size="large"/>
       </div>) :(
      <div className="tablesorter" responsive>
      <Table className="tablesorter">
        <thead className="text-primary">
          <tr>
            <th className="text-center">ID/TOPIC</th>
            <th className="text-center">Name</th>
            <th className="text-center">Type</th>
            <th className="text-center">Unit</th>
            <th className="text-center">Protocol</th>
            <th className="text-center">Last Reading</th>
            <th className="text-center"></th>
          </tr>
        </thead>
        <tbody>
          {!devices || devices.length <= 0 ? (
            <tr>
              <td colSpan="6" align="center">
                <b>Currently, there are no devices added to the control panel.</b>
              </td>
            </tr>
          ) : (
            devices.map(device => (
              <tr key={device.pk}>
                <td className="text-center">{device.sensor_id}</td>
                <td className="text-center">{device.title}</td>
                <td className="text-center">{device.measure_type}</td>
                <td className="text-center">{device.unit_label}</td>
                <td className="text-center">{device.connector_used}</td>
                <td className="text-center">{device.last_received}</td>
                <td className="text-center"></td>
                <td align="center">
                  <NewDeviceModal
                    create={false}
                    device={device}
                    resetState={this.props.resetState}
                    gateways={this.props.gateways}
                  />
                  &nbsp;&nbsp;
                  <ConfirmRemovalModal
                    pk={device.pk}
                    resetState={this.props.resetState}
                    link={link}
                  />
                </td>
              </tr>
            ))
          )}

        </tbody>
      </Table>
      </div>
    )}
    </CardBody>
  </Card>


  </>
      );
  }
}

export default DeviceList;
