import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, Button, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { Icon } from "@ant-design/compatible";
import axios from "axios";
import { config } from './Constants'

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

var API_URL = config.url.API_URL+'/device/';


class ConfirmRemovalModal extends Component {
  constructor(props) {
    super(props);
    this.state = {   modal: false};
  }


  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  deleteStudent = (pk, link) => {
    axios.delete(API_URL+link+pk).then(() => {
      this.props.resetState();
      this.toggle();
    });
  };

  render() {
    return (
      <Fragment>
            <>
              <Button
                color="danger"
                type="button"
                id="tooltip457194720"
                className="float-right"
                size="sm"
                onClick={() => this.toggle()}
              >
                <Icon type="delete" style={{ fontSize: '16px'}}/>
              </Button>
              <UncontrolledTooltip
                delay={0}
                target="tooltip457194720"
                placement="right"
              >
              Remove
              </UncontrolledTooltip>
            </>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Do you really want to delete this item?
          </ModalHeader>

          <ModalFooter>
            <Button
              size="sm"
              type="button"
              onClick={() => this.toggle()}>
              Cancel
            </Button>
            <Button
              size="sm"
              className="float-right"
              color="danger"
              onClick={() => this.deleteStudent(this.props.pk, this.props.link)}
            >
              Yes
            </Button>


          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default ConfirmRemovalModal;
