import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import DeviceList from "../components/DeviceList";
import NewDeviceModal from "../components/NewDeviceModal";
import ExportModal from "../components/ExportModal";

import { Auth } from 'aws-amplify';
import { config } from '../components/Constants'
import axios from "axios";

var API_URL = config.url.API_URL+ '/device/';




class Home extends Component {
  state = {
    loading: true,
    devices: [],
    gateways: [],
    username:''
  };

  async componentDidMount() {
    const userInfo = await Auth.currentUserInfo();

    this.setState({
         username: userInfo.attributes.email
       });
    this.resetState();
    this.getGateways();
  }

  getDevices = () => {
    axios.get(API_URL+"list/", {params: { user_id: this.state.username }})
    .then(res => this.setState({ devices: res.data, loading:false }));

  };

  getGateways = () => {
    axios.get(API_URL+"gateway_list/", {params: { user_id: this.state.username }} )
    .then(res => this.setState({ gateways: res.data}));
  };


  resetState = () => {
    this.getDevices();

  };

  render() {
    return (
      <div className="content">
      <Container style={{ marginTop: "20px" }}>
        <Row>
          <Col>
            <DeviceList
             loading={this.state.loading}
              devices={this.state.devices}
              resetState={this.resetState}
              gateways={this.state.gateways}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <NewDeviceModal
              loading={this.state.loading}
              create={true}
              gateways={this.state.gateways}
              resetState={this.resetState}
              />
              <ExportModal
                loading={this.state.loading}
                devices={this.state.devices}
                />
          </Col>
        </Row>
      </Container>
      </div>
    );
  }
}

export default Home;
