
import styled from "styled-components";
import { Link } from "react-router-dom";
import IoTArchitecture from '../assets/img/IoTArchitecture.png';



export function BlogItem({ blog }) {
  return (
    <BlogItemContainer to={`/blogs/${blog.pk}`}>
      <BlogImageContainer>
        { blog && blog.image ? 
          (<img src={blog.image} alt={blog.title} />):
          (<img src={IoTArchitecture} alt={blog.title} />)}
      </BlogImageContainer>
      <p>{blog.title}</p>
    </BlogItemContainer>
  );
}




const BlogItemContainer = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 362px;
  border-radius: 30px;
  overflow: hidden;
  background: ${(props) => props.theme.pink};
  &:nth-child(even) {
    background: ${(props) => props.theme.green};
    p {
      color: ${(props) => props.theme.white};
    }
  }
  p {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    text-align: center;
    padding: 0 5px;
  }
  &:hover div img {
    transform: scale(1.1);
  }
`;

const BlogImageContainer = styled.div`
  width: 100%;
  height: 268px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.4s;
  }
`;
