import React, { Component, useEffect } from 'react';

import ChartRenderer from '../ChartRenderer.js';
import SelectChartType from "./SelectChartType";
import SelectDataSource from "./SelectDataSource";

import axios from "axios";

import { Auth } from 'aws-amplify';

import { Row, Col, Divider, Card} from "antd";


const API_URL = "/device/"


class ExplorePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username:'',
      chartData: [], chartType: "line", //default to line chart
      isFetching: false, dataSources: [], dataSourceName: this.props.vizState["signalName"],
      dataSourceID: this.props.vizState["query"], addingToDashboard: false, setAddingToDashboard: false,
      setVizState: null
    };
    this.timer = null;
    this.handleSourceChange = this.handleSourceChange.bind(this);
    this.handleChartChange = this.handleChartChange.bind(this);
  }

// TODO: I will need to work on User authentification and authorization
  async componentDidMount() {

    if (this.props.vizState["chartType"]){
      this.setState({chartType: this.props.vizState["chartType"]})
    }

    this.timer = setInterval(() => this.getData(), 5000);

    const userInfo = await Auth.currentUserInfo();

    this.setState({
         username: userInfo.attributes.email
       });

    axios.get(API_URL+"list/", {params: { user_id: this.state.username }})
       .then(response => {
         this.setState({dataSources: response.data})
       })
       .catch(e => console.log(e));

     }


  handleSourceChange(source) {
    //updating the state to the current selected visual
    this.setState({dataSourceName: source.title,
                   dataSourceID: source.sensor_id,
                   isFetching: true });
    this.getData()
  }

  handleChartChange(type) {
    //updating the state to the current selected visual
    this.setState({chartType: type});
  }

  getData(){
     this.props.setVizState({query: this.state.dataSourceID,
                             signalName:this.state.dataSourceName,
                             chartType: this.state.chartType});
 }
  render() {
    return (
        <>
        <Row
          type="flex"
          justify="space-around"
          align="top"
          gutter={24}
          style={{
            marginBottom: 12
          }}
          >
          <Col span={24}>
            <Card>
                <Col span={24}>
                <SelectChartType
                  chartType={this.state.chartType}
                  updateChartType={this.handleChartChange}
                />
                <Divider type="vertical" />
                <SelectDataSource
                  availableSources={this.state.dataSources}
                  selectedDataSource={this.state.dataSourceName}
                  updateDataSource={this.handleSourceChange}
                />
                </Col>
            </Card>
          </Col>
         <Col span={24}>
         { this.state.dataSourceID ? (
           <Card
             style={{
               minHeight: 420
             }}
             extra={this.props.chartExtra}
           >
                  <ChartRenderer
                    vizState={{ query: this.state.dataSourceID,
                                signalName:this.state.dataSourceName,
                                chartType: this.state.chartType }}
                  />
              </Card>

         ):(
           <h2
             style={{
               marginTop: "50px",
               textAlign: "center"
             }}
           >
             Select your data source and chart type.
           </h2>
            )
          }
          </Col>
        </Row>

        </>
    );
  }
}

export default ExplorePage;
