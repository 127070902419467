import React, { Component } from "react";
import { Button, Card, CardHeader, CardBody, CardTitle  } from "reactstrap";
import { Spin } from "antd";


class StoreKey extends Component {


  render() {
    var title = "Generate Key";
    const gateways = this.props.gateways;
    const storedata = this.props.storedata
    const link = "gateway_update/";

    if (this.props.storedata) {
      title = "Update key";
    }

    return (
      <>


  <Card border="info">
    <CardHeader>
      <CardTitle tag="h6">HTTP</CardTitle>
    </CardHeader>
    <CardBody>
    { this.props.loading ? (
      <div style={{textAlign:'center'}}>
       <Spin tip="Loading..." size="large"/>
      </div>) :(
    <p className="text-muted">
    { !storedata ? (
      <b>No storekey currently available.
      Click on Generate Key to obtain your unique key for POST requests.
       </b>
    ):(
      storedata.map(key =>(
      <>
      <li className="text-success">Your key to store data using HTTP POST requests is:<code> {key.store_key}</code>.</li>
      <li className="text-danger">Your key is confidential.</li>
      </>
    ))

    )
    }
    </p>
  )}
    </CardBody>
  </Card>
  <Button
    disabled={this.props.loading}
    color="info"
    className="float-right"
    onClick={this.props.generate}
    style={{ marginBottom: "0px",marginTop: "0px", }}
    size="sm">
    {title}
  </Button>
  </>
    );
  }
}

export default StoreKey;
