import React from "react";

const Box = (props) => {
  const { title, description, btnClass, btnTitle, price, feature } = props;
  return (
    <div className="col-4 text-center">
    <div className="features-tiles-item-content">
      <h1 className="mt-0 mb-8 text-color-primary">
        {title}
        </h1>
      <h5>
        {description}
      </h5>
    </div>
      <div className="text-center">
      <thead  className="text-info">
        <th className="text-center"></th>
      </thead >
      </div>
      <div className="card-body">
        <h1 className="card-title pricing-card-title">
          ${price} <h4 className="text-info"> per device</h4>
        </h1>
        <ul className="list-unstyled mt-3 mb-4">
          {feature &&
            feature.map((data, index) => {
              return <li key={index}>{data}</li>;
            })}
        </ul>
      
      </div>
    </div>
  );
};

export default Box;
