import React from "react";
import { Button, Form, FormGroup, Input, Label, Row, Col } from "reactstrap";
import { Auth } from 'aws-amplify';
import { config } from './Constants';
import axios from "axios";



axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
var API_URL = config.url.API_URL+'/device/';

class NewMemberForm extends React.Component {
  state = {
    pk: 0, name: "",
    email_address: "",
    cell_number: "",
    notify_email: false,
    notify_cell: false};

  async componentDidMount() {
    if (this.props.member) {
      const { pk, name, email_address, cell_number, notify_email, notify_cell } = this.props.member;
      this.setState({ pk, name, email_address, cell_number, notify_email, notify_cell });
    }

    const userInfo = await Auth.currentUserInfo();
    this.setState({
         user: userInfo.attributes.email
       });
     }


  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChecked = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };
  createMember = e => {
    e.preventDefault();
    axios.post(API_URL+"recipient_list/", this.state).then(() => {
      this.props.resetState();
      this.props.toggle();
    });
  };

  editMember = e => {
    e.preventDefault();
    axios.put(API_URL+"recipient_update/" + this.state.pk, this.state).then(() => {
      this.props.resetState();
      this.props.toggle();
    });
  };

  defaultIfEmpty = value => {
    return value === "" ? "" : value;
  };

  render() {
    // const Gateways = this.props.gateways;
    return (
      <Form onSubmit={this.props.member ? this.editMember : this.createMember}>
        <FormGroup>
          <Label for="name">Name:</Label>
          <Input
            type="text"
            name="name"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.name)}
            maxLength="20"
            placeholder="Provide contact name..."
          />
        </FormGroup>
        <Row form>
        <Col md={6}>
        <FormGroup>
          <Label for="email_address">E-mail:</Label>
          <Input
            type="email"
            name="email_address"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.email_address)}
            maxLength="20"
            placeholder="Provide e-mail address..."
          />
        </FormGroup>
        </Col>
        <Col md={6}>
        <FormGroup>
          <Label for="cell_number">Cell:</Label>
          <Input
            type="text"
            name="cell_number"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.cell_number)}
            placeholder="Provide phone number..."
          />
        </FormGroup>
        </Col>
        </Row>
       <FormGroup>
         <Input
            style={{marginLeft:"5px"}}
            type="checkbox"
            name="notify_email"
            checked={this.state.notify_email}
            onChange={this.onChecked}/>
         <Label style={{marginLeft:"30px"}}check>
          Notify by E-mail
         </Label>
       </FormGroup>
       <FormGroup>
         <Input
            style={{marginLeft:"5px"}}
            type="checkbox"
            name="notify_cell"
            checked={this.state.notify_cell}
            onChange={this.onChecked}/>
         <Label style={{marginLeft:"30px"}}check>
          Notify by Cell
         </Label>
       </FormGroup>
        <Button className="float-right btn-fill" color="info">Save</Button>
      </Form>

    );
  }
}

export default NewMemberForm;
