/*!

=========================================================
* Routes for
=========================================================
*/
import Dashboard from "./views/Dashboard.jsx";
import Notifications from "./views/Notifications.jsx";
import Gateways from "./views/Gateways.jsx";
import Maps from "./views/Maps.jsx";
import SignOut from "./views/SignOut.jsx";
import UserPage from "./views/Settings.jsx";
import ChartMod from "./views/ExplorePage.jsx";
import Alerts from "./views/Alerts.jsx";
import Teams from "./views/Teams.jsx";
var dashRoutes = [
  {
    path: "/main",
    name: "Dashboard",
    icon: "tim-icons icon-laptop",
    component: Dashboard,
    layout: "/dashboard"
  },
  {
    path: "/maps",
    name: "Map",
    icon: "tim-icons icon-square-pin",
    component: Maps,
    layout: "/dashboard"
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "tim-icons icon-bell-55",
    component: Notifications,
    layout: "/dashboard"
  },
  {
    path: "/settings",
    name: "Devices",
    icon: "tim-icons icon-vector",
    component: UserPage,
    layout: "/dashboard"
  },
  {
    path: "/gateway",
    name: "Gateways",
    icon: "tim-icons icon-world",
    component: Gateways,
    layout: "/dashboard"
  },
  {
    path: "/alerts",
    name: "Alerts",
    icon: "tim-icons icon-alert-circle-exc",
    component: Alerts,
    layout: "/dashboard"
  },
  {
    path: "/chartsettings",
    name: "Charts",
    icon: "tim-icons icon-chart-bar-32",
    component: ChartMod,
    layout: "/dashboard"
  },
  {
    path: "/team",
    name: "Team",
    icon: "tim-icons icon-chat-33",
    component: Teams,
    layout: "/dashboard"
  },
  {
    path: "/signout",
    name: "Sign Out",
    icon: "tim-icons icon-spaceship",
    component: SignOut,
    layout: "/dashboard"
  },

];
export default dashRoutes;
