import { BlogItem } from "./BlogItem";
import styled from "styled-components";

export function BlogList({ blogs }) {
  return (
    <BlogsListContainer>
      {blogs.map((item) => {
        return <BlogItem key={item.pk} blog={item} />;
      })}
    </BlogsListContainer>
  );
}


export const BlogsListContainer = styled.section`
 display: grid;
 grid-template-columns: repeat(3, 1fr);
 grid-gap: 3rem;
`;
