import React from "react";
import * as PropTypes from "prop-types";
import { Menu } from "antd";
import { Icon } from "@ant-design/compatible";
import ButtonDropdown from "./ButtonDropdown";


const SelectDataSource = ({ availableSources, selectedDataSource, updateDataSource }) => {
  var title = "Data Source"
  if (selectedDataSource){
    title = selectedDataSource;
  }
  const menu = (
    <Menu>
      {availableSources.map(dataSource => (
        <Menu.Item key={dataSource.title} onClick={() => updateDataSource(dataSource)}>
          {dataSource.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <ButtonDropdown overlay={menu} icon={<Icon type="cloud-sync"/>} >
      {title}
    </ButtonDropdown>
  );
};

SelectDataSource.propTypes = {
  availableSources: PropTypes.array.isRequired,
  selectedDataSource: PropTypes.string.isRequired,
  updateDataSource: PropTypes.func.isRequired
};
export default SelectDataSource;
