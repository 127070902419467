import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import Main from '../components/Layout/Main';
import { Spin } from "antd";
import  Chip  from '../components/Chip';
import { Button, Modal, UncontrolledTooltip, CardBody, CardTitle, CardHeader, Card } from "reactstrap";
import { config } from '../components/Constants';
import IoTArchitecture from '../assets/img/IoTArchitecture.png';
var API_URL = config.url.API_URL+ '/core/';


export function BlogPage() {
  const params = useParams();
  const [blog, setBlog] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  async function getBlogDetails() {
    if (!params.pk) return;
    setIsLoading(true);
    const response = await axios.get(
      API_URL+"blogs/"+params.pk );

    setBlog(response.data);
    setIsLoading(false);
  }

  useEffect(() => {
    getBlogDetails();
  }, []);

  return (

    <>

      {!isLoading ? (
        <BlogWrap>
          <PostHeader>

            <PostTitle>{blog.title}</PostTitle>
            <BlogSubCategory>
            {blog.tags && blog.tags.map((category, i) => (
                <div key={i}>
                  <Chip label={category} />
                </div>
              ))}
            </BlogSubCategory>
          </PostHeader>
          {!blog && blog.image ?(
            <img src={blog.image} alt='cover' />
          ):(
            <img src={IoTArchitecture} alt='cover' />
          )}

          <BlogDesc dangerouslySetInnerHTML={{__html: blog.content}} />
          <BlogDate>Published on {blog.created_on}</BlogDate>
        </BlogWrap>
      ) : (
        <div style={{textAlign:'center'}}>
         <Spin tip="Loading..." size="large"/>
        </div>
      )}
    </>


  );
}






const BlogSubCategory = styled.div`
  display: flex;
  justify-content: center;
   margin: 1rem;
`;



const BlogWrap = styled.div`
  max-width: 800px;
  margin: 8rem auto;
  background: white;
`;

const BlogDate = styled.div`
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
`;

const PostHeader = styled.div`
   margin: 0.67em 0;
   font-size: 1em;
   text-align: center;
`;

const PostTitle = styled.h1`
   color:black;
`;
const BlogDesc = styled.div`
  padding: 1rem;
  margin-top: 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 1.2em;
  color:grey;
`;

const PostOverviewContainer = styled.section`
  display: flex;
  height: 350px;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background: white;
  margin-top: 60px;
  img {
    width: 400px;
    height: 100%;
    object-fit: cover;
  }
  div {
    font-size: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  strong {
    color: ${(props) => props.theme.primary};
  }
  @media (max-width: 750px) {
    img {
      width: 300px;
    }
  }
  @media (max-width: 665px) {
    img {
      width: 200px;
    }
  }
  @media (max-width: 550px) {
    flex-direction: column;
    height: auto;
    margin-top: 10px;
    img {
      width: 100%;
      height: 200px;
    }
  }
`;

const BasePageContainer = styled.main`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 60px;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 1000px) {
    max-width: 800px;
  }
`;

const PostContainer = styled(BasePageContainer)`
  margin-bottom: 60px;
  h3 {
    color: ${(props) => props.theme.primary};
    font-size: 30px;
    margin-top: 60px;
    margin-bottom: 30px;
  }
  h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
  }
`;


export default BlogPage;
