import React, { Component } from "react";
import { Card, CardHeader, CardBody, CardTitle, Table  } from "reactstrap";
import { Spin } from "antd";
import NewAlertModal from "../components/NewAlertModal.js";

import ConfirmRemovalModal from "../components/ConfirmRemovalModal.js";

class AlertList extends Component {


  render() {
    const alerts = this.props.alerts;
    const link = 'alert_update/';
    return (
  <Card border="info">
    <CardHeader>
      <CardTitle tag="h6">Alert List</CardTitle>
    </CardHeader>
    <CardBody>
    { this.props.loading ? (
      <div style={{textAlign:'center'}}>
       <Spin tip="Loading..." size="large"/>
      </div>) :(
      <div className="tablesorter" responsive>
      <Table hover variant="dark">
        <thead className="text-info">
          <tr>
            <th className="text-center">Status</th>
            <th className="text-center">ID</th>
            <th className="text-center">Condition</th>
            <th className="text-center">Test Value</th>
            <th className="text-center">Read Count</th>
            <th className="text-center">Recipients</th>
            <th className="text-center">Status</th>
            <th className="text-center"></th>
          </tr>
        </thead>
        <tbody>
          {!alerts || alerts.length <= 0 ? (
            <tr>
              <td colSpan="6" align="center">
                <b>Currently, there are no alerts added to the control panel.</b>
              </td>
            </tr>
          ) : (
            alerts.map(alert => (

              <tr key={alert.pk}>
                <td className="text-center">{alert.active? "Enabled": "Disabled"}</td>
                <td className="text-center">{alert.sensor}</td>
                <td className="text-center">{alert.condition}</td>
                <td className="text-center">{alert.test_value}</td>
                <td className="text-center">{alert.read_count}</td>
                <td className="text-center">{alert.alert_recipients}</td>
                <td className="text-center">{alert.status? "Active":"Inactive"}</td>
                <td className="text-center"></td>
                <td align="center">
                  <NewAlertModal
                    create={false}
                    alert={alert}
                    resetState={this.props.resetState}
                    devices={this.props.devices}
                    recipients={this.props.recipients}
                  />
                  &nbsp;&nbsp;
                  <ConfirmRemovalModal
                    pk={alert.pk}
                    resetState={this.props.resetState}
                    link={link}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      </div>
    )}
    </CardBody>
  </Card>
    );
  }
}

export default AlertList;
