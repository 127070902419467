


import { Auth } from 'aws-amplify';

import {createBrowserHistory} from "history";

export default function Home({ updateAuthState }) {
  const hist = createBrowserHistory({forceRefresh:true});
  async function signOut() {
    try {
      await Auth.signOut();
      hist.push("/");
    } catch (error) {
    }
  }
  return (
    signOut()
  );
}
