import React from "react";
import { Button, Form, FormGroup,CustomInput, Input, Label, Col, Row} from "reactstrap";
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import axios from "axios";
import { config } from './Constants'

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

var API_URL = config.url.API_URL+'/device/';

const CONDITION_CHOICES = [{name: ">",       title: "greater than"},
                           {name: ">=",      title: "greater than or equal to"},
                           {name: "<",       title: "less than"},
                           {name: "<=",      title: "Less Than or Equal To"},
                           {name: "==",      title: "Equal To"},
                           {name: "!=",      title: "Not Equal To"},
                           {name: "inactive",title: "Inactive"}]

const NOTIFICATION_CHOICES = [{name: "email",       title: "E-mail only"},
                              {name: "sms",         title: "SMS only"},
                              {name: "sms&email",   title: "E-mail and SMS"},
                              {name: "nothing",     title: "Do not notify"}]

class NewAlertForm extends React.Component{

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = { pk: 0, active: true, sensor: '', condition: ">",
                   test_value: 0, read_count:1, alert_recipients:[]};
  }


  async componentDidMount() {
    if (this.props.alert) {
      const { pk, active, sensor, condition, test_value, read_count, alert_recipients} = this.props.alert;
      this.setState({ pk, active, sensor, condition, test_value, read_count, alert_recipients });
    }
    const userInfo = await Auth.currentUserInfo();
    this.setState({
         user: userInfo.attributes.email
       });
  }

  handleChange(event) {
    //logging for testing
    event.preventDefault();
  }

  updateRecipient = (e)=> {
    let arr = [];
    console.log("the target value", e.target.value)
    arr.push(e.target.value);
    this.setState({alert_recipients:arr});
    console.log("the recipient list is", this.state.alert_recipients)
 };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    console.log("the name is", e.target.value)

    if (e.target.name==="notify_method"){
      if((e.target.value==="email") || (e.target.value==="sms&email")){
        console.log("setting email to true")
        this.setState({notify_email:true})
      } else {this.setState({notify_email:false});console.log("setting email to false")};
      if((e.target.value==="sms") || (e.target.value==="sms&email")){
        this.setState({notify_cell:true});
        console.log("setting cell to true");
      } else {this.setState({notify_cell:false});
    console.log("setting cell to false")}
    }
  };

  createAlert = e => {
    e.preventDefault();
    axios.post(API_URL+"alert_list/", this.state).then(() => {
      this.props.resetState();
      this.props.toggle();
    });
  };

  editAlert = e => {
    e.preventDefault();
    axios.put(API_URL+"alert_update/" + this.state.pk, this.state).then(() => {
      this.props.resetState();
      this.props.toggle();
    });
  };

  defaultIfEmpty = value => {
    return value === "" ? "" : value;
  };
 // Change SensorTypes to DeviceList
  render(){
    const SensorTypes = this.props.devices;
    return (
      <Form onSubmit={this.props.alert ? this.editAlert: this.createAlert}>
      <Row form>
        <Col sm={6} md={6}>
          <FormGroup>
            <Label for="active">Status:</Label>
            <Input
              type="select"
              name="active"
              onChange={this.onChange}
              value={this.defaultIfEmpty(this.state.active)}>
                <option value={true}>Enabled</option>
                <option value={false}>Disabled</option>
            </Input>
          </FormGroup>
        </Col>
        <Col sm={6} md={6}>
      <FormGroup>
        <Label for="sensor">Device:</Label>
        <Input
          type="select"
          name="sensor"
          onChange={this.onChange}
          value={this.defaultIfEmpty(this.state.sensor)}
        >
        <option>Select a device...</option>
        {
          SensorTypes.map( m => (
            <option value={m.sensor_id}>{m.title}</option>
          ))
        }
        </Input>
      </FormGroup>
      </Col>
      </Row>
      <Row form>
        <Col sm={4} md={4}>
          <FormGroup>
            <Label for="condition">Condition:</Label>
            <Input type="select" name="condition" onChange={this.onChange} value={this.defaultIfEmpty(this.state.condition)}>
            {
              CONDITION_CHOICES.map( m => (
                <option value={m.name}>{m.title}</option>
              ))
            }
            </Input>
          </FormGroup>
        </Col>
        <Col sm={4} md={4}>
          <FormGroup>
            <Label for="test_value">Test Value:</Label>
            <Input
              type="text"
              name="test_value"
              onChange={this.onChange}
              value={this.defaultIfEmpty(this.state.test_value)}
              maxLength="15"
            />
          </FormGroup>
        </Col>
        <Col sm={4} md={4}>
          <FormGroup>
            <Label for="read_count">Read Counts:</Label>
            <Input
              type="text"
              name="read_count"
              onChange={this.onChange}
              value={this.defaultIfEmpty(this.state.read_count)}
              maxLength="2"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
      <FormGroup>
      <Row form>
      <Col md={12}>
      <Label for="alert_recipients">Notify:</Label>
      <Input type="select" name="alert_recipients" onChange={this.updateRecipient}>
      {
        this.props.recipients.map(m => (
          <option value={m.name}>{m.name}</option>
        ))
      }
      </Input>
      </Col>
      </Row>
      </FormGroup>
      </Row>
        <Button
        color="info"
        className="float-right"
        onClick={this.toggle}
        style={{ minWidth: "100px" }}>
        Save
        </Button>
      </Form>

    );

  }
}


NewAlertForm.propTypes = {
  onSubmit: PropTypes.func,

};
export default NewAlertForm;
