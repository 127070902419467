import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import MemberList from "../components/MemberList";
import NewMemberModal from "../components/NewMemberModal";
import { Auth } from 'aws-amplify';
import axios from "axios";

const API_URL = "/device/";

class Home extends Component {
  state = {
    members: [],
    loading: true,
    username:''
  };

  async componentDidMount() {
    const userInfo = await Auth.currentUserInfo();
    this.setState({
         username: userInfo.attributes.email
       });
    this.resetState();
  }

  getMembers = () => {
    axios.get(API_URL+"recipient_list/", {params: { user_id: this.state.username }} )
    .then(res => this.setState({ members: res.data, loading:false }));
  };

  resetState = () => {
    this.getMembers();
  };

  render() {
    return (
      <div className="content">
      <Container style={{ marginTop: "20px" }}>
        <Row>
          <Col>
            <MemberList
              loading={this.state.loading}
              members={this.state.members}
              resetState={this.resetState}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <NewMemberModal
              create={true}
              resetState={this.resetState}
            />
          </Col>
        </Row>
      </Container>
      </div>
    );
  }
}

export default Home;
