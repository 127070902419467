  import React from 'react';
  import classNames from 'classnames';
  import { SectionTilesProps } from "./../utils/SectionProps";
  import SectionHeader from './partials/SectionHeader';
  // import Image from '../elements/Image';
  import Box from "../elements/Box";
  import { Icon } from "@ant-design/compatible";
import { Card, CardHeader,  CardBody, CardTitle, Table, CardText  } from "reactstrap";
  const propTypes = {
    ...SectionTilesProps.types
  }

  const defaultProps = {
    ...SectionTilesProps.defaults
  }
  const Pricing = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
  }) => {

    const featureBox1 = [
      "1 user included",
      "500 MB of storage",
      "Email notification support",
      "Data updated every five minutes"
    ];
    const featureBox2 = [
      "5 users included",
      "2 GB of storage",
      "Phone and email notification support",
      "Data updated every minute"
    ];
    const featureBox3 = [
      "15 users included",
      "15 GB of storage",
      "Phone and email notification support",
      "Data updated every 15 seconds"
    ];

    const outerClasses = classNames(
      'features-tiles section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner pt-0',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap center-content',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Pricing',
      paragraph: 'All-Inclusive – Simple and Predictable Pricing.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
            <div style={{textAlign:'left'}}>
            <Card >
              <CardHeader>
                <CardTitle  tag="h4">We’ve made our pricing model as simple and predictable as possible.</CardTitle>
              </CardHeader>
              <CardBody className="card-body">
              <CardText>
               <span className="text-info">Step 1: </span> Pay a one-time entry fee for each license type.
              </CardText>
              <CardText>
                <span className="text-info">Step 2: </span>Pay a monthly fee for each device registered under the license type.
              </CardText>

              </CardBody>
            </Card>
            </div>
              <div className="row">
                  <Box
                    price="0"
                    title="Academic"
                    description="An academic license is available for academic research and teaching. NexGen IoT is available as a free service for small non-commercial academic projects. A $15 entry fee is required for this license."
                    btnClass="btn-outline-info"
                    btnTitle="Sign up for free"
                    feature={featureBox1}
                  />
                  <Box
                    feature={featureBox2}
                    price="15"
                    description="A standard license is available for a commercial use. The standard license allows you to connect more sensors, and get access to paid-only features. A $175 entry fee is required for this license."
                    title="Starter"
                    btnClass="btn-primary"
                    btnTitle="Get started"
                  />
                  <Box
                    feature={featureBox3}
                    price="29"
                    description="We’re able to offer affordable pricing. To switch to a predictable and transparent IoT connectivity solution, get in touch with us. A $300 entry fee is required for this license."
                    title="Standard"
                    btnClass="btn-primary"
                    btnTitle="Contact us"
                  />
                </div>


            </div>
          </div>
        </div>
      </section>
    );
  }

  Pricing.propTypes = propTypes;
  Pricing.defaultProps = defaultProps;

  export default Pricing;
