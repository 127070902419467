
import React, { Component } from "react";


// I need to fix the this.setstate. This context is lost.
import {
  Card,
  CardHeader,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col} from "reactstrap";
import { Spin } from "antd";

import moment from "moment";



class Notifications extends Component {

  constructor(props) {
    super(props);
    this.countProperties = this.countProperties.bind(this);
    }

  countProperties(obj) {
     var count = 0;
     for(var prop in obj) {
         if(obj.hasOwnProperty(prop))
             ++count;
           }
     return count;
     }







  render() {

    const number_notifications = this.countProperties(this.props.notifications);

    return (

      <div className="content">

      <Row>
        <Col lg="12" md="12">
          <Card className="card-tasks">
            <CardHeader>
              <h6 className="title d-inline">Notifications ({number_notifications})</h6>
              <UncontrolledDropdown>
                <DropdownToggle
                  caret
                  className="btn-icon"
                  color="link"
                  data-toggle="dropdown"
                  type="button"
                >
                  <i className="tim-icons icon-settings-gear-63" />
                </DropdownToggle>
                <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                  <DropdownItem
                    href="/"
                    onClick={this.props.onClick}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </CardHeader>
            <CardBody>
            { this.props.loading ? (
              <div style={{textAlign:'center'}}>
               <Spin tip="Loading..." size="large"/>
              </div>) :(

              <div className="table-full-width table-responsive">
                <Table>
                  <tbody>
                  {!this.props.notifications || this.props.notifications.length <= 0? (
                    <tr>
                      <td colSpan="6" align="center">
                        <b>Currently, there are no notifications.</b>
                      </td>
                    </tr>
                  ):(
                    this.props.notifications.map( notification => (
                    <tr key={notification.pk}>
                    <td >
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            value={notification.pk}
                            onChange={(e) => this.props.toggleCheckbox(e, notification)}/>
                          <span className="form-check-sign">
                            <span className="check" />
                          </span>
                        </Label>
                      </FormGroup>
                    </td>
                      <td>
                        <p className="title" style={{color: 'primary'}}>{moment(notification.last_notified).format('lll')}: {notification.title}</p>
                        <p className="text-muted">
                          {notification.body}
                        </p>
                      </td>

                    </tr>
                  ))
                )}
                  </tbody>
                </Table>
              </div>
            )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      </div>

    );
  }
}

export default Notifications;
