import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { LinearProgress, Typography} from '@material-ui/core';


import {
  CartesianGrid,
  XAxis,
  YAxis, Tooltip, ResponsiveContainer,
  Legend, BarChart, Bar,
  LineChart, Line} from "recharts";

import GaugeChart from 'react-gauge-chart'

import axios from "axios";
import moment from "moment";
import numeral from "numeral";

import LedWidget from './Widgets/LEDWidget';
import DoorWidget from './Widgets/DoorWidget';

const API_URL_FETCH = "/payload_parsing/fetch/"


const numberFormatter = item => numeral(item).format("0,0");

//const dateFormatter = item => moment(item).format("MMM YY");
const dateFormatter = item => moment(item).format("lll");


const colors = ["#7DB3FF", "#49457B", "#FF7C78"];
const xAxisFormatter = (item) => {
  if (moment(item).isValid()) {
    return dateFormatter(item)
  } else {
    return item;
  }
}

const CartesianChart = ({ resultSet, children, ChartComponent, height }) => (
    <ResponsiveContainer width="100%" height={250}>
      <ChartComponent margin={{ left: -10 }}  data={resultSet[1]}>
       <XAxis  axisLine={false} tickLine={false} tickFormatter={xAxisFormatter} dataKey="ts" minTickGap={10}/>
       <YAxis  type="number" domain={['auto' , 'auto']} dataKey="val" allowDataOverflow={true} axisLine={false} tickLine={false} tickFormatter={numberFormatter}/>
       <CartesianGrid vertical={false}/>
       {children}
      <Legend />
       <Tooltip
          labelStyle={{ color: "black" }}
          itemStyle={{ color: "red" }}
          cursor={{ stroke: 'red', strokeWidth: 2 }}
          labelFormatter={dateFormatter}
          formatter={numberFormatter}/>
      </ChartComponent>
    </ResponsiveContainer>
)


const TypeToChartComponent = {
  doorwidget:({ resultSet }) => (
    <DoorWidget
       mqttMessage={resultSet[1].length > 1 ? (resultSet[1][resultSet[1].length-1].val):("false")}
     />
  ),
  ledwidget:({ resultSet }) => (
    <LedWidget
       mqttMessage={resultSet[1].length > 1 ? (resultSet[1][resultSet[1].length-1].val):("false")}
     />
  ),
  gauge: ({ resultSet }) => (

    <GaugeChart id="gauge-chart5"
      nrOfLevels={420}
      arcsLength={[0.3, 0.3, 0.3,0.3]}
      colors={['#5BE12C', '#EA4228']}
      percent={resultSet[1].length > 1 ? (resultSet[1][resultSet[1].length-1].val):(0)}
      animate={false}
      arcWidth={0.1}
      needleColor={"#0F0F17"}
      needleBaseColor={"#0F0F17"}
      arcPadding={0.02}
    />

  ),


  line: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
{resultSet[0] && resultSet[0].map((series, i) => (
    <Line
      key={series.key}
      stackId="a"
      dataKey="val"
      name={series.title}
      stroke={colors[i]}
    />
    ))}

    </CartesianChart>
  ),
  bar: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={BarChart}>
      {resultSet[0] && resultSet[0].map((series, i) => (
        <Bar
          key={series.key}
          stackId="a"
          dataKey="val"
          name={series.title}
          fill={colors[i]}
        />
      ))}
    </CartesianChart>
  ),
  number: ({ resultSet }) => (
    <Typography
      variant="h4"
      style={{
        textAlign: "center",
        color: "#fff"
      }}
    >
      {resultSet[1].length > 1 ? (resultSet[1][resultSet[1].length-1].val):(0)}
    </Typography>
  ),
  // area: ({ resultSet }) => (
  //   <CartesianChart resultSet={resultSet} ChartComponent={AreaChart}>
  //     {resultSet[0] && resultSet[0].map((series, i) => (
  //       <Area
  //         key={series.key}
  //         stackId="a"
  //         dataKey={series.key}
  //         name={series.title}
  //         stroke={colors[i]}
  //         fill={colors[i]}
  //       />
  //     ))}
  //   </CartesianChart>
  // ),
  // pie: ({ resultSet }) => (
  //   <ResponsiveContainer width="100%" height={350}>
  //     <PieChart>
  //       <Pie
  //         isAnimationActive={false}
  //         data={resultSet.chartPivot()}
  //         nameKey="x"
  //         dataKey={resultSet.seriesNames()[0].key}
  //         fill="#8884d8"
  //       >
  //         {resultSet.chartPivot().map((e, index) => (
  //           <Cell key={index} fill={colors[index % colors.length]} />
  //         ))}
  //       </Pie>
  //       <Legend />
  //       <Tooltip />
  //     </PieChart>
  //   </ResponsiveContainer>
  // ),

};

const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map(key => ({
    [key]: React.memo(TypeToChartComponent[key])
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderChart = Component => ({ resultSet, error, ...props }) =>
  (resultSet[1] && <Component resultSet={resultSet} {...props} />) ||
  (error && error.toString()) || <LinearProgress/>;



//  Chartrender is being called multiple times. Plus, the new data check is failing.

const ChartRenderer = ({ vizState}) => {
  const {query, signalName, chartType} = vizState;
  const [data, setData] = useState([]);
  const [initial, setInitial] = useState([]);
  let sync_time = 4500;
  let mounted = true;
  const getData = async () => {
      const result = await axios(API_URL_FETCH, {params: { sensor_id: query, sensor_name:signalName}});
          if(mounted){
            setData(result.data);
            setInitial(value => false);
        }
      }

  useEffect(async () => {

      const timer = setInterval(() => getData(), sync_time);
      return () => {
        mounted = false;
        clearInterval(timer);
      };
    }, []);

  const component = TypeToMemoChartComponent[chartType];

  return component && renderChart(component)({"resultSet":data});
};


ChartRenderer.propTypes = {
  vizState: PropTypes.object,
};
ChartRenderer.defaultProps = {
  vizState: {},
  chartHeight: 100,
};
export default ChartRenderer;
