import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';
import logo from './../../../../assets/img/logo.svg';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
      <Link to="/">
        <img
          src={logo}
          width={65}
          height={65}
           />
      </Link>
      </h1>
    </div>
  );
}

export default Logo;
