import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from "./../utils/SectionProps";
import SectionHeader from './partials/SectionHeader';
// import Image from '../elements/Image';
import { Icon } from "@ant-design/compatible";

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'An IoT connectivity solution for your business',
    paragraph: 'A scalable IoT solution to merge the physical and digital worlds into  deployable, end-user ready IoT Applications that deliver insights and solve problems'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <head>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9271173893957702"
     crossorigin="anonymous"></script>
      </head>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <Icon type={"setting"} style={{ padding: '16px',fontSize: '28px', color: '#FFF' }} />

                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h3 className="mt-0 mb-8 text-color-primary">
                    Device Management
                    </h3>
                  <h4>
                    This platform provides full device management capabilities
                    that will enable you to centrally manage the entire fleet of IoT devices through
                    a single pane of glass and greatly reduce operating costs.
                    </h4>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <Icon type={"gateway"} style={{ padding: '16px',fontSize: '28px', color: '#FFF' }} />
                  </div>
                </div>
                </div>
                <div className="features-tiles-item-content">
                  <h3 className="mt-0 mb-8 text-color-primary">
                    Connectivity
                  </h3>
                  <h4>
                    We offer solid connectivity management that provides secure and effortless incorporation of diverse
                    IoT devices and the administration of multi-protocol & multi-network connections.
                  </h4>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <Icon type={"database"} style={{ padding: '16px',fontSize: '28px', color: '#FFF' }} />
                  </div>
                </div>
                </div>
                <div className="features-tiles-item-content">
                  <h3 className="mt-0 mb-8 text-color-primary">
                    Data Collection
                    </h3>
                  <h4>
                    Configure your gateways to securely send IoT data to the cloud via HTTP or MQTT.
                    Payloads are processed and stored without latency.
                  </h4>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <Icon type={"dashboard"} style={{ padding: '16px',fontSize: '28px', color: '#FFF' }} />
                  </div>
                </div>
                </div>
                <div className="features-tiles-item-content">
                  <h3 className="mt-0 mb-8 text-color-primary">
                    Data Visualization
                  </h3>
                  <h4>
                    Create custom dashboards. Choose between dozens of built-in widgets.
                    Drag and drop to create compelling visualizations of real-time data.
                  </h4>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Icon type={"alert"} style={{ padding: '16px',fontSize: '28px', color: '#FFF' }} />
                  </div>
                </div>
                </div>
                <div className="features-tiles-item-content">
                  <h3 className="mt-0 mb-8 text-color-primary">
                    Alerts and Notifications
                    </h3>
                  <h4>
                    Configure email and text alerts based on IoT real-time data
                    to monitor and fix issues with devices to provide more proactive customer service.
                  </h4>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
