import React from 'react';
// import sections
import Hero from '../components/HomePage/sections/Hero';
import FeaturesTiles from '../components/HomePage/sections/FeaturesTiles';
import FeaturesSplit from '../components/HomePage/sections/FeaturesSplit';

// import Testimonial from '../components/HomePage/sections/Testimonial';
// import Cta from '../components/HomePage/sections/Cta';
import Main from '../components/Layout/Main';
const HomePage = () => {

  return (
    <>
      <Main title={"NexGen IoT — Home"} description={"Remote control your IoT devices with our P2P IoT connectivity platform. Direct and secure connections with low latency and maximum privacy."}/>
      <Hero className="illustration-section-01" />
      <FeaturesTiles />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
    </>
  );
}

export default HomePage;
