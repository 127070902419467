import React, { Component } from "react";
import { Card, CardHeader,  CardBody, CardTitle, Table  } from "reactstrap";
import { Spin } from "antd";
import NewMemberModal from "../components/NewMemberModal.js";

import ConfirmRemovalModal from "../components/ConfirmRemovalModal.js";

class TeamList extends Component {


  render() {
    const members = this.props.members;
    const link = 'recipient_update/';

    return (
  <Card border="info">
    <CardHeader>
      <CardTitle tag="h6">Team List</CardTitle>
    </CardHeader>
    <CardBody>
    { this.props.loading ? (
      <div style={{textAlign:'center'}}>
       <Spin tip="Loading..." size="large"/>
      </div>) :(
      <div className="tablesorter" responsive>
      <Table responsive hover size="xs">
        <thead className="text-info">
          <tr>
            <th className="text-center">Name</th>
            <th className="text-center">E-mail</th>
            <th className="text-center">Cell</th>
            <th className="text-center">Notify by Email</th>
            <th className="text-center">Notify by Cell</th>
            <th className="text-center"></th>
          </tr>
        </thead>
        <tbody>
          {!members || members.length <= 0 ? (
            <tr>
              <td colSpan="6" align="center">
                <b>Currently, there are no members added to your team.</b>
              </td>
            </tr>
          ) : (
            members.map(member => (
              <tr key={member.pk}>
                <td className="text-center">{member.name}</td>
                <td className="text-center">{member.email_address ? member.email_address:"N/A"}</td>
                <td className="text-center">{member.cell_number ? member.cell_number:"N/A"}</td>
                <td className="text-center">{member.notify_email ? "Yes":"No"}</td>
                <td className="text-center">{member.notify_cell ? "Yes":"No"}</td>
                <td className="text-center"></td>
                <td align="center">
                  <NewMemberModal
                    create={false}
                    member={member}
                    resetState={this.props.resetState}
                  />
                  &nbsp;&nbsp;
                  <ConfirmRemovalModal
                    pk={member.pk}
                    resetState={this.props.resetState}
                    link={link}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      </div>
    )}
    </CardBody>
  </Card>
    );
  }
}

export default TeamList;
