import React, { useState } from "react";
// nodejs library that concatenates classes

import { Card,
        CardHeader,
        CardBody, UncontrolledDropdown,
        DropdownToggle, DropdownMenu, DropdownItem,
         } from "reactstrap";
import { useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { GET_DASHBOARD_ITEMS } from "../graphql/queries";
import { DELETE_DASHBOARD_ITEM } from "../graphql/mutations";

const DashboardItemDropdown = ({ itemId }) => {
  const [removeDashboardItem] = useMutation(DELETE_DASHBOARD_ITEM, {
    refetchQueries: [
      {
        query: GET_DASHBOARD_ITEMS
      }
    ]
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const dashboardItemDropdownMenu = (
  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
      <DropdownItem>
        <Link to={`/main/chartsettings?itemId=${itemId}`}>Edit</Link>
      </DropdownItem>
      <DropdownItem
        onClick={() =>
              removeDashboardItem({
                variables: {
                  id: itemId
                }
              })
        }
      >
        Delete
      </DropdownItem>
    </DropdownMenu>
  );
  return (
  <UncontrolledDropdown>
    <DropdownToggle
      caret
      className="btn-icon"
      color="link"
      data-toggle="dropdown"
      type="button"
    >
    <i className="tim-icons icon-settings-gear-63" />
    </DropdownToggle>

    <DropdownMenu aria-labelledby="dropdownMenuLink" right>
      <DropdownItem>
        <Link to={`/dashboard/chartsettings?itemId=${itemId}`}>Edit</Link>
      </DropdownItem>
      <DropdownItem
        onClick={() =>
              removeDashboardItem({
                variables: {
                  id: itemId
                }
              })
            }
      >
        Delete
      </DropdownItem>
    </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const DashboardItem = ({ itemId, children, title }) => (
  <>
  <Card
    className="card-chart"
    size="small"
    title={title}
    style={{ height: "100%", width: "100%"
    }}
  >
  <CardHeader>
  <h5 className="title d-inline"> {title}</h5>
    <DashboardItemDropdown itemId={itemId} />
  </CardHeader>
  <CardBody>
  <div className="chart-area">
    {children}
  </div>
  </CardBody>
  </Card>
  </>
);

export default DashboardItem;
