import React, { Component, Fragment } from "react";
import { Button,
  Modal, UncontrolledTooltip, CardBody, CardTitle, CardHeader, Card } from "reactstrap";
import NewDeviceForm from "./NewDeviceForm";
import { Icon } from "@ant-design/compatible";
class NewDeviceModal extends Component {
  state = {
    modal: false
  };

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  render() {
    const create = this.props.create;

    var title = "Edit Device";
    var button = ( <>
                    <Button
                      color="secondary"
                      type="button"
                      id="tooltip1"
                      className="float-right"
                      onClick={this.toggle}
                      size="sm"
                      >
                        <Icon type="edit" style={{ fontSize: '16px'}}/>
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="tooltip1"
                        placement="right"
                      >
                        Edit Device Settings
                      </UncontrolledTooltip>
                   </> );
    if (create) {
      title = "New Device";
      button = (
        <Button
          disabled={this.props.loading}
          color="info"
          className="float-right"
          onClick={this.toggle}
          size="sm"
        >
          Create New
        </Button>
      );
    }
    return (
      <Fragment>
        {button}
        <Modal isOpen={this.state.modal} toggle={this.toggle}>

          <Card style={{ marginBottom: "0px"}}>
          <CardHeader>
              <CardTitle tag="h6">{title}</CardTitle>
          </CardHeader>

            <CardBody>
            <NewDeviceForm
              resetState={this.props.resetState}
              toggle={this.toggle}
              device={this.props.device}
              gateways={this.props.gateways}
            />
            </CardBody>
          </Card>
        </Modal>
      </Fragment>
    );
  }
}

export default NewDeviceModal;
