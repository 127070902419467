import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import AlertList from "../components/AlertList";
import NewAlertModal from "../components/NewAlertModal";
import { Auth } from 'aws-amplify';
import axios from "axios";
import { config } from '../components/Constants'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'


var API_URL = config.url.API_URL+ '/device/';




class Home extends Component {
  state = {
    loading: true,
    devices: [], alerts:[],recipients:[],
    username:''
  };

  async componentDidMount() {
    const userInfo = await Auth.currentUserInfo();

    this.setState({
         username: userInfo.attributes.email
       });
    this.resetState();

  }

  getAlerts = () => {
    axios.get(API_URL+'alert_list/', {params: { user_id: this.state.username }} )
    .then(res => this.setState({ alerts: res.data, loading:false }));
  };

  getDevices = () => {
    axios.get(API_URL+'list/', {params: { user_id: this.state.username }})
    .then(res => this.setState({ devices: res.data, loading:false }));
  };
  getRecipients = () => {
    axios.get(API_URL+'recipient_list/', {params: { user_id: this.state.username }})
    .then(res => this.setState({ recipients: res.data }));
  };
  resetState = () => {
    this.getDevices();
    this.getRecipients();
    this.getAlerts();
  };

  render() {
    return (
      <div className="content">
      <Container style={{ marginTop: "20px" }}>
        <Row>
          <Col>
            <AlertList
              loading={this.state.loading}
              alerts={this.state.alerts}
              devices={this.state.devices}
              resetState={this.resetState}
              recipients={this.state.recipients}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <NewAlertModal
              create={true}
              devices={this.state.devices}
              resetState={this.resetState}
              recipients={this.state.recipients} />
          </Col>
        </Row>
      </Container>
      </div>
    );
  }
}

export default Home;
