import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from './../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
// import Image from '../elements/Image';
import agriculture from './../../../assets/img/agriculture.jpg';
import manufacturing from './../../../assets/img/manufacturing.jpeg';
import fleet from './../../../assets/img/fleet_management.png';
const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Free consulation',
    paragraph: 'Have an idea? Share it with us and our experts will help you find the best solution. We are passionate about cutting-edge IoT applications and are eager to offer advice.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  IoT in Agriculture
                  </div>
                <h3 className="mt-0 mb-12">
                  Smart Farming
                  </h3>

                <h4>
                  Most popular smart agriculture gadgets are weather stations, combining various smart farming sensors.
                  Located across the field, they collect various data from the environment and send it to the cloud.
                  The provided measurements can be used to map the climate conditions, choose the appropriate crops,
                  and take the required measures to improve their capacity (i.e. precision farming).
                </h4>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <img
                  src={agriculture}
                  alt="Features split 01"
                  width={528}
                  height={396} />

              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  IoT in Asset Management
                  </div>
                <h3 className="mt-0 mb-12">
                  Fleet Monitoring
                  </h3>
                <h4>
                  The application of the Internet of Things to fleet management assists with geolocation (and with it the monitoring
                  of routes and identification of the most efficient routes), performance analysis, telemetry control and fuel savings,
                  the reduction of polluting emissions to the environment and can even provide valuable information to improve the driving of vehicles.
                </h4>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <img
                  src={fleet}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  IoT in Manufacturing
                  </div>
                <h3 className="mt-0 mb-12">
                  Maintenance Management
                  </h3>
                <h4>
                  The real-time monitoring of physical assets allows determining when a measurement is out of range and it is necessary
                  to perform condition-based maintenance (CBM), or even applying Artificial Intelligence (AI) algorithms such as Machine
                  Learning or Deep Learning to predict the failure before it happens.
                </h4>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <img
                  src={manufacturing}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
