import { PaginationItem } from "./PaginationItem";
import styled, { css } from "styled-components";


const siblingsCount = 1;

function generatePagesArray(from, to) {
  return [...new Array(to - from)]
    .map((_, index) => {
      return from + index + 1;
    })
    .filter((page) => page > 0);
}

export function Pagination({
  totalCountOfRegisters,
  currentPage = 1,
  onPageChange,
  registerPerPage = 10,
}) {
  const lastPage = Math.ceil(totalCountOfRegisters / registerPerPage);

  const previousPages =
    currentPage > 1
      ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
      : [];

  const nextPages =
    currentPage < lastPage
      ? generatePagesArray(
          currentPage,
          Math.min(currentPage + siblingsCount, lastPage)
        )
      : [];

  return (
    <PaginationContainer>
      {currentPage > 1 + siblingsCount && (
        <>
          <PaginationItem onPageChange={onPageChange} number={1} />
          {currentPage > 2 + siblingsCount && (
            <PaginationItemContainer disabled>...</PaginationItemContainer>
          )}
        </>
      )}

      {previousPages.length > 0 &&
        previousPages.map((page) => (
          <PaginationItem
            onPageChange={onPageChange}
            key={page}
            number={page}
          />
        ))}

      <PaginationItem
        onPageChange={onPageChange}
        number={currentPage}
        isCurrent
      />

      {nextPages.length > 0 &&
        nextPages.map((page) => (
          <PaginationItem
            onPageChange={onPageChange}
            key={page}
            number={page}
          />
        ))}

      {currentPage + siblingsCount < lastPage && (
        <>
          {currentPage + 1 + siblingsCount > siblingsCount && (
            <PaginationItemContainer disabled>...</PaginationItemContainer>
          )}
          <PaginationItem onPageChange={onPageChange} number={lastPage} />
        </>
      )}
    </PaginationContainer>
  );
}

const PaginationContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 60px;
`;

const PaginationItemContainer = styled.button`
  min-width: 30px;
  height: 30px;
  padding: 0px 10px;
  border-radius: 6px;
  border: none;
  background: ${(props) => props.theme.green};
  color: ${(props) => props.theme.white};
  font-weight: 600;
  &:disabled {
    opacity: 0.5;
    cursor: inherit;
  }
  ${(props) =>
    props.isCurrent &&
    css`
      background: ${props.theme.primary};
      min-width: 50px;
      &:disabled {
        opacity: 1;
      }
    `}
`;
