import React from 'react'




import { Card, CardBody} from "reactstrap";
import { IconContext } from "react-icons";
import { FaDoorOpen, FaDoorClosed } from "react-icons/fa";

class DoorWidget extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			lastValue: false
		}
	}

	static getDerivedStateFromProps(props, state){
  		var mqttMessage = props.mqttMessage
  		var currentValue;
  		if (mqttMessage != null ) {
  			if (props.mqttMessage === "true") {
  				currentValue = true;
  			} else {
  				currentValue = false
  			}
  		} else {
  			currentValue = state.lastValue
  		}
  		return {
  			lastValue: currentValue
  		}
  	}


	render() {
		const statusElement = this.state.lastValue ?
    <h5>
    <IconContext.Provider value={{ color: "green", size: "2em"}}>
      <FaDoorClosed/> Closed
    </IconContext.Provider>
    </h5>:
    <h5>
    <IconContext.Provider value={{ color: "red", size: "2em"}}>
      <FaDoorOpen/> Opened
    </IconContext.Provider>
    </h5>
		return (
			<Card className="card-chart text-center" style={{height:100}}>
        <CardBody>
				{statusElement}
        </CardBody>
      </Card>
		);
	}
}

export default DoorWidget;
