import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import DeviceList from "../components/DeviceList";
import ExportModal from "../components/ExportModal";
import { Auth } from 'aws-amplify';
import axios from "axios";
import { config } from '../components/Constants'


axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

var API_URL = config.url.API_URL+'/device/'




class MapDevices extends Component {
  state = {
    loading: true,
    devices: [],
    gateways: [],
    username:''
  };

  async componentDidMount() {
    const userInfo = await Auth.currentUserInfo();

    this.setState({
         username: userInfo.attributes.email
       });
    this.resetState();
    this.getGateways();
  }

  getDevices = () => {
    axios.get(API_URL+"list/", {params: { user_id: this.state.username, type:"location" }})
    .then(res => this.setState({ devices: res.data, loading:false }));
  };

  getGateways = () => {
    axios.get(API_URL+"gateway_list/", {params: { user_id: this.state.username }} )
    .then(res => this.setState({ gateways: res.data}));
  };


  resetState = () => {
    this.getDevices();

  };

  render() {
    return (
      <div className="content">
      <Container style={{ marginTop: "20px" }}>
        <Row>
          <Col>
            <DeviceList
              loading={this.state.loading}
              devices={this.state.devices}
              resetState={this.resetState}
              gateways={this.state.gateways}
            />
          </Col>
        </Row>
        <Row>
          <Col>
              <ExportModal
                loading={this.state.loading}
                devices={this.state.devices}
                />
          </Col>
        </Row>
      </Container>
      </div>
    );
  }
}

export default MapDevices;
