import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from './../utils/SectionProps';
// import SectionHeader from './partials/SectionHeader';
import team from './../../../assets/img/team.png';
const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const AboutUs = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  // const tilesClasses = classNames(
  //   'tiles-wrap',
  //   pushLeft && 'push-left'
  // );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );



  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className={splitClasses}>

          <div className="split-item">
            <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
              <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                About NexGen Solutions
                </div>
              <h3 className="mt-0 mb-12">
                We develop and test custom applications.
                </h3>
              <h4>
              NexGen IoT is a company that focuses in the design, implementation, and testing of Internet of Things (IoT) solutions to address operational challenges, production optimization and cost-reduction plans.
              We specialize in solving technically difficult projects by offering customized software solutions. Our expertise inlcudes responsive web applications using proven technologies such as
              Ruby, Python, React, Oracle, and PostgreSQL. Our cross-functional experts help organizations and individuals harness the right IoT technology stack to build data-rich software solutions
              for connected vehicles, smart facilities, personal devices, industrial purposes, and beyond.
              We are commited to fulfill our client’s business needs by working closely with them during the development cycle when prioritizing and making critical project decisions.
              </h4>
            </div>
            <div className={
              classNames(
                'split-item-image center-content-mobile reveal-from-bottom',
                imageFill && 'split-item-image-fill'
              )}
              data-reveal-container=".split-item">
              <img
                src={team}
                alt="Features split 01"
                width={300}
                height={396} />
            </div>
          </div>

          </div>
        </div>
      </div>
    </section>
  );
}

AboutUs.propTypes = propTypes;
AboutUs.defaultProps = defaultProps;

export default AboutUs;
