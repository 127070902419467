import React from "react";
import * as PropTypes from "prop-types";
import { Menu } from "antd";
import { Icon } from "@ant-design/compatible";
import ButtonDropdown from "./ButtonDropdown";
const ChartTypes = [
  {
    name: "number",
    title: "Number",
    icon: "info-circle"
  },
  {
    name: "line",
    title: "Line",
    icon: "line-chart"
  },
  {
    name: "bar",
    title: "Bar",
    icon: "bar-chart"
  },
  {
    name: "gauge",
    title: "Gauge",
    icon: "dashboard"
  },
  {
    name: "ledwidget",
    title: "Led Widget",
    icon: "bulb"
  },
  {
    name: "doorwidget",
    title: "Door Widget",
    icon: "unlock"
  },

  // {
  //   name: "map",
  //   title: "Map",
  //   icon: "global"
  // },

];

const SelectChartType = ({ chartType, updateChartType }) => {

  const menu = (
    <Menu>
      {ChartTypes.map(m => (
        <Menu.Item key={m.title} onClick={() => updateChartType(m.name)}>
          <Icon type={m.icon} />
          {m.title}
        </Menu.Item>
      ))}
    </Menu>
  );
  const foundChartType = ChartTypes.find(t => t.name === chartType);
  return (
    <ButtonDropdown overlay={menu} icon={<Icon type={foundChartType.icon} />}>
      {foundChartType.title}
    </ButtonDropdown>
  );
};

SelectChartType.propTypes = {
  chartType: PropTypes.string.isRequired,
  updateChartType: PropTypes.func.isRequired
};
export default SelectChartType;
