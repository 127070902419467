import React from 'react';
// import sections
// import Hero from '../components/HomePage/sections/Hero';
// import FeaturesTiles from '../components/HomePage/sections/FeaturesTiles';
// import FeaturesSplit from '../components/HomePage/sections/FeaturesSplit';
// import Testimonial from '../components/HomePage/sections/Testimonial';
// import AboutUs from '../components/HomePage/sections/AboutUs';
// import Cta from '../components/HomePage/sections/Cta';
// import Contact from '../components/HomePage/sections/Contact';
import Pricing from '../components/HomePage/sections/Pricing';
import Main from '../components/Layout/Main';
const PricingPage = () => {

  return (
    <>
      <Main title={"NexGen IoT — Pricing"}/>
      <Pricing/>
    </>
  );
}

export default PricingPage;
