import React, { Component, Fragment } from "react";
import { Button, Modal, UncontrolledTooltip, CardBody, CardTitle, CardHeader, Card } from "reactstrap";

import ExportForm from "./ExportForm";
class ExportModal extends Component {
  state = {
    modal: false
  };

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  render() {

    var title = "Export Data";
    var button = ( <>
                    <Button
                      disabled={this.props.loading}
                      color="secondary"
                      type="button"
                      id="tooltip3"
                      className="float-right"
                      onClick={this.toggle}
                      size="sm"
                      >
                        Export Data
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="tooltip3"
                        placement="right"
                      >
                        Export Device Data
                      </UncontrolledTooltip>
                   </> );
    return (
      <Fragment>
        {button}
        <Modal isOpen={this.state.modal} toggle={this.toggle}>

          <Card style={{ marginBottom: "0px"}}>
          <CardHeader>
              <CardTitle tag="h6">{title}</CardTitle>
          </CardHeader>

            <CardBody>
              <ExportForm
                devices={this.props.devices}
              />
            </CardBody>
          </Card>
        </Modal>
      </Fragment>
    );
  }
}

export default ExportModal;
