import React, { Component } from "react";
import { Card, CardHeader,  CardBody, CardTitle, Table  } from "reactstrap";
import { Spin } from "antd";
import NewGatewayModal from "../components/NewGatewayModal.js";

import ConfirmRemovalModal from "../components/ConfirmRemovalModal.js";

class DeviceList extends Component {


  render() {
    const gateways = this.props.gateways;
  const link = "gateway_update/";
    return (
  <>
  <Card border="info">
    <CardHeader>
      <CardTitle tag="h6">MQTT</CardTitle>
    </CardHeader>
    <CardBody>
    { this.props.loading ? (
      <div style={{textAlign:'center'}}>
       <Spin tip="Loading..." size="large"/>
      </div>) :(
      <div className="tablesorter" responsive>
      <Table hover variant="dark">
        <thead className="text-info">
          <tr>
            <th className="text-center">ID</th>
            <th className="text-center">Name</th>
            <th className="text-center">Username</th>
            <th className="text-center">Password</th>
            <th className="text-center">Broker</th>
            <th className="text-center">Status</th>
            <th className="text-center"></th>
          </tr>
        </thead>
        <tbody>
          {!gateways || gateways.length <= 0 ? (
            <tr>
              <td colSpan="6" align="center">
                <b>Currently, there are no devices added to the control panel.</b>
              </td>
            </tr>
          ) : (
            gateways.map(gateway => (
              <tr key={gateway.pk}>
                <td className="text-center">{gateway.mqtt_client_id}</td>
                <td className="text-center">{gateway.mqtt_client_name}</td>
                <td className="text-center">{gateway.mqtt_client_username}</td>
                <td className="text-center">*********</td>
                <td className="text-center">{gateway.mqtt_client_broker}</td>
                <td className="text-center">
                  {gateway.mqtt_client_status ?
                      ("Connected") : ("Disconnected")
                  }
                </td>
                <td className="text-center"></td>
                <td align="center">
                  <NewGatewayModal
                    create={false}
                    gateway={gateway}
                    resetState={this.props.resetState}
                  />
                  &nbsp;&nbsp;
                  <ConfirmRemovalModal
                    pk={gateway.pk}
                    resetState={this.props.resetState}
                    link={link}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      </div>
    )}
    </CardBody>
  </Card>
  </>
    );
  }
}

export default DeviceList;
