import React, { useState, useReducer } from 'react'
import { Auth } from 'aws-amplify';


import {  Button, Input, Col, FormGroup} from "reactstrap";
import { notification, Spin } from 'antd';
import Main from '../components/Layout/Main';

import axios from "axios";
import { config } from '../components/Constants';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'


var API_URL = config.url.API_URL+ "/account/";

const initialFormState = {
  family_name:'',given_name:'',
  address:'', phone_number:'', username: '', password: '', email: '', confirmationCode: ''
}



export default function Form() {

  const [formType, updateFormType] = useState('signIn');
  const [formState, updateFormState] = useReducer(reducer, initialFormState);
  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);


  const openNotificationWithIcon = (type, msg, description) => {
    notification[type]({
      message: msg,
      description:description,
    });
  };


  function reducer(state, action) {
    switch(action.type) {
      case 'updateFormState':
        return {
          ...state, [action.e.target.name]: action.e.target.value

        }
      default:
        return state
    }
  }

  async function signUp({ family_name, given_name, address, phone_number, password, email }, updateFormType) {
    try {
      await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          family_name: family_name,
          given_name: given_name,
          address:address,
          phone_number: phone_number
             },
      })
      updateFormType('confirmSignUp')
    } catch (err) {
      notification.error({
                message: 'Error during signed up!',
                description: err.message,
                placement: 'topRight',
                duration: 2
              });
    }
  }

  async function confirmSignUp({ email, confirmationCode }, updateFormType) {

    try {
      await Auth.confirmSignUp(email, confirmationCode)
      updateFormType('signIn')
    } catch (err) {

    }
  }

  async function signIn({ username, password }) {

    try {
      await Auth.signIn(username, password);
      axios.post(API_URL+"create_account/", {"email":username}).then(() => {
        console.log("account_created");
      });

    } catch (err) {
      notification.error({
                message: 'Error during sign in!',
                description: err.message,
                placement: 'topRight',
                duration: 2
              });
    }
  }


  async function recPass({username}, updateFormType) {

    setIsSendingCode(true);

    try {

      await Auth.forgotPassword(username);
      updateFormType('confirmRecovery')
    } catch (err) {
      notification.error({
                message: 'Error during recovery!',
                description: err.message,
                placement: 'topRight',
                duration: 2
              });
     setIsSendingCode(false);

    }
  }


  async function confirmRecovery({ email, confirmationCode, password }, updateFormType) {

    setIsConfirming(true);

    try {
      await Auth.forgotPasswordSubmit(email, confirmationCode, password);
      updateFormType('signIn');
    } catch (err) {
      notification.error({
                message: 'Error during sign in!',
                description: err.message,
                placement: 'topRight',
                duration: 2
              });

      setIsConfirming(false);
    }
  }

  function renderForm() {
    switch(formType) {
      case 'signUp':
        return (
          <SignUp
            signUp={() => signUp(formState, updateFormType)}
            updateFormState={e => updateFormState({ type: 'updateFormState', e })}
          />
        )
      case 'confirmSignUp':
        return (
          <ConfirmSignUp
            confirmSignUp={() => confirmSignUp(formState, updateFormType)}
            updateFormState={e => updateFormState({ type: 'updateFormState', e })}
          />
        )
      case 'signIn':
        return (
          <SignIn
            signIn={() => signIn(formState)}
            updateFormState={e => updateFormState({ type: 'updateFormState', e })}
          />
        )
      case 'recPass':
        return (
          <RecPass
            recPass={() => recPass(formState,  updateFormType)}
            updateFormState={e => updateFormState({ type: 'updateFormState', e })}
            disabled={isSendingCode}
            loading={codeSent}
          />
          )
      case 'confirmRecovery':
        return (
          <ConfirmRecovery
            confirmRecovery={() => confirmRecovery(formState, updateFormType)}
            updateFormState={e => updateFormState({ type: 'updateFormState', e })}
            disabled={isConfirming}
            />
            )
      default:
        return null
    }
  }


  return (
    <div>

      <div>
        <Main title={"NexGen IoT — log in or sign up"}/>
        {renderForm(formState)}

      </div>

      {
        formType === 'signUp' && (
          <p style={styles.footer}>
            Already have an account? <span
              style={styles.anchor}
              onClick={() => updateFormType('signIn')}
            >Sign In</span>
          </p>
        )
      }
      {
        formType === 'signIn' && (
          <>
          <p style={styles.footer}>
            <span
              style={styles.anchor}
              onClick={() => updateFormType('recPass')}
            >Forgot password?</span>
          </p>
          <p style={styles.footer}>
            Need an account? <span
              style={styles.anchor}
              onClick={() => updateFormType('signUp')}
            >Sign Up</span>
          </p>

          </>
        )
      }
      {
        formType === 'recPass' && (
          <>
          <p style={styles.footer}>
            Remember your password? <span
              style={styles.anchor}
              onClick={() => updateFormType('signIn')}
            >Sign In</span>
          </p>

          </>
        )
      }

    </div>

  )
}

function SignUp(props) {
  return (
    <div style={styles.container}>
    <FormGroup row>
      <Col sm={6}>
         <Input type="text" style={styles.input}  maxLength="20" onChange={e => {e.persist();props.updateFormState(e)}} name="family_name" id="family_name" placeholder="Family name..." />
      </Col>
      <Col sm={6}>
         <Input type="text" style={styles.input} maxLength="20" onChange={e => {e.persist();props.updateFormState(e)}} name="given_name" id="given_name" placeholder="Given name..." />
      </Col>
     </FormGroup>
     <FormGroup row>
       <Col sm={6}>
        <Input type="tel"  style={styles.input} maxLength="14" onChange={e => {e.persist();props.updateFormState(e)}} name="phone_number" id="phone_number" placeholder="+40(770)-888-444" />
       </Col>
       <Col sm={6}>
          <Input type="email" style={styles.input} onChange={e => {e.persist();props.updateFormState(e)}} name="email" id="email" placeholder="E-mail..." />
       </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={6}>
             <Input type="text" style={styles.input} onChange={e => {e.persist();props.updateFormState(e)}} name="address" id="address" placeholder="Address..." />
        </Col>
       <Col sm={6}>
            <Input type="password" style={styles.input} onChange={e => {e.persist();props.updateFormState(e)}} name="password" id="password" placeholder="password ..." />
        </Col>
      </FormGroup>
      <Button style={styles.button} onClick={props.signUp} className="float-right btn-fill" color="info">Sign Up</Button>
    </div>
  )
}

function SignIn(props) {
  return (
    <div style={styles.container}>

     <FormGroup row>
        <Input type="text" style={styles.input} onChange={e => {e.persist();props.updateFormState(e)}} name="username" id="username" placeholder="Username..." />
     </FormGroup>
     <FormGroup row>
        <Input type="password" style={styles.input} onChange={e => {e.persist();props.updateFormState(e)}} name="password" id="password" placeholder="Password..." />
     </FormGroup>
      <Button style={styles.button} onClick={props.signIn} className="float-right btn-fill" color="info">Sign In</Button>
    </div>
  )
}

function ConfirmSignUp(props) {
  return (
    <div style={styles.container}>
      <input
        name='confirmationCode'
        placeholder='Confirmation Code'
        onChange={e => {e.persist();props.updateFormState(e)}}
        style={styles.input}
      />
      <button onClick={props.confirmSignUp} style={styles.button}>
        Confirm Sign Up
      </button>
    </div>
  )
}

function RecPass(props) {
  return (
    <div style={styles.container}>
    {props.disabled ? (<Spin tip="Loading..." size="large"/>):(
      <>
     <FormGroup row>
        <Input type="text" style={styles.input} onChange={e => {e.persist();props.updateFormState(e)}} name="username" id="username" placeholder="Username..." />
     </FormGroup>
     <FormGroup row>
      <Button  style={styles.button} disabled={props.disabled} onClick={props.recPass}className="float-right btn-fill" color="info">Reset</Button>
      </FormGroup>
      </>
    )}
    </div>
  )
}

function ConfirmRecovery(props) {

  return (

    <div style={styles.container}>
    {props.disabled ? (<Spin tip="Loading..." size="large"/>):(
      <>
    <FormGroup row>
       <Input type="text" style={styles.input} onChange={e => {e.persist();props.updateFormState(e)}} name="email" id="email" placeholder="Username..." />
    </FormGroup>
    <FormGroup row>
       <Input type="text" style={styles.input} onChange={e => {e.persist();props.updateFormState(e)}} name="confirmationCode" id="confirmationCode" placeholder="Confirmation Code..." />
    </FormGroup>
    <FormGroup row>
       <Input type="password" style={styles.input} onChange={e => {e.persist();props.updateFormState(e)}} name="password" id="password" placeholder="Password..." />
    </FormGroup>

     <Button  style={styles.button}  disabled={props.disabled} onClick={props.confirmRecovery}  color="info">Reset Password</Button>
     </>
   )
}
    </div>


  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 150,
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    backgroundColor: 'rgba(0,0,0,.25)',
    height: 40,
    marginTop: 8,
    width: 300,
    maxWidth: 350,
    padding: '0px 8px',
    fontSize: 16,
    outline: 'none',
    border: 'none',
    borderBottom: '2px solid rgba(0, 0, 0, .3)'
  },
  button: {
    backgroundColor: '#006bfc',
    textAlign: 'center',
    color: 'white',
    height: 40,
    marginTop: 10,
    fontWeight: '600',
    fontSize: 14,
    cursor: 'pointer',
    border:'none',
    outline: 'none',
    borderRadius: 3,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, .3)',
  },
  footer: {
    fontWeight: '600',
    padding: '0px 25px',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.6)'
  },
  anchor: {
    color: '#006bfc',
    cursor: 'pointer'
  }
}
