import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';



const Main = (props) => (
  <Helmet>
    <meta charset="utf-8"/>
    <meta name="viewport" content="width=device-width, initial-scale=1"/>
    <title>{props.title}</title>
    <meta name="robots" content="index, follow" />
    <meta name="description" content={props.description} />
    <meta name="theme-color" content="#008f68" />
    <meta name="language" content="English" />
    <meta property="og:locale" content="en_US"/>
    <meta property="og:title" content={props.title}/>
    <meta property="og:url" content="https://hiveqore.com/"/>
    <meta property="og:description" content={props.description}/>
    <meta property="og:site_name" content="Hiveqore"/>
    <meta name="author" content="nexgen systems"/>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9271173893957702"
     crossorigin="anonymous"></script>
     <link rel="canonical" href="https://hiveqore.com/"/>
  </Helmet>

);

Main.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

Main.defaultProps = {
  title: "NexGen IoT",
  description: "Next Generation IoT Systems",
};

export default Main;
