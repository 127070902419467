import React from "react";
import { Button, Row, Col, Form, FormGroup, Input, Label,
         FormFeedback } from "reactstrap";
import { Auth } from 'aws-amplify';
import { config } from './Constants'
import axios from "axios";


const SensorTypes = [{name: "temperature", title: "Temperature Sensor"},
                     {name: "location",    title: "Location Sensor"},
                     {name: "pressure",    title: "Pressure Sensor"},
                     {name: "other",       title: "Other"}];

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

var API_URL = config.url.API_URL+'/device/';



class NewDeviceForm extends React.Component {
  state = {
    pk: 0,
    sensor_id: "",
    title: "",
    measure_type: "N/A",
    unit_label: "",
    connector_used:"N/A",
    user:'',
    validate: {
        sensorIDState: '',
      },
  };

  async componentDidMount() {
    if (this.props.device) {
      const { pk, sensor_id, title, measure_type, unit_label, connector_used } = this.props.device;
      this.setState({ pk, sensor_id, title, measure_type, unit_label, connector_used });
    }
    const userInfo = await Auth.currentUserInfo();
    this.setState({
         user: userInfo.attributes.email
       });
     }


  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };



  createDevice = e => {
    e.preventDefault();
    axios.post(API_URL+"list/", this.state).then(() => {
      this.props.resetState();
      this.props.toggle();
    });
  };

  editDevice = e => {
    e.preventDefault();
    axios.put(API_URL+"add/" + this.state.pk, this.state)
    .then(() => {
      this.props.resetState();
      this.props.toggle();})
  };

  defaultIfEmpty = value => {
    return value === "" ? "" : value;
  };

  validateSensorID = e => {
   const emailRex =  /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
   const { validate } = this.state
     if (emailRex.test(e.target.value)) {
       validate.sensorIDState = 'has-danger'
     } else {
       validate.sensorIDState = 'has-success'
     }
     this.setState({ validate })
   }

  render() {
    const Gateways = this.props.gateways;
    return (
      <Form onSubmit={this.props.device ? this.editDevice : this.createDevice}>
      <Row form>
      <Col md={6}>
        <FormGroup>
          <Label for="sensor_id">ID:</Label>
          <Input
            type="text"
            name="sensor_id"
            onChange={(e) => {
                              this.onChange(e)
                              this.validateSensorID(e) } }
            value={this.defaultIfEmpty(this.state.sensor_id)}
            maxLength="20"
            disabled={this.props.device ? true : false}
            placeholder="Provide device ID..."
            valid={ this.state.validate.sensorIDState === 'has-success' }
            invalid={ this.state.validate.sensorIDState === 'has-danger' }
          />
          <FormFeedback valid>
                A valid a table ID.
          </FormFeedback>
          <FormFeedback>
              Special characters and spaces are not allowed. Please enter a valid ID.
          </FormFeedback>

        </FormGroup>
        </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="title">Name:</Label>
              <Input
                type="text"
                name="title"
                onChange={this.onChange}
                value={this.defaultIfEmpty(this.state.title)}
                maxLength="20"
                placeholder="Provide device name..."
              />
            </FormGroup>
          </Col>
        </Row>
          <Row form>
            <Col md={6}>
        <FormGroup>
          <Label for="measure_type">Type:</Label>
          <Input
            type="select"
            name="measure_type"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.measure_type)}
          >
          <option>N/A</option>
          {
            SensorTypes.map(m => (
              <option value={m.name}>{m.title}</option>
            ))
          }
          </Input>
        </FormGroup>
            </Col>
        <Col md={6}>
        <FormGroup>
          <Label for="unit_label">Unit:</Label>
          <Input
            type="text"
            name="unit_label"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.unit_label)}
            maxLength="15"
            placeholder="Provide device unit..."
          />
        </FormGroup>
        </Col>
        </Row>
        <FormGroup>
          <Label for="connector_used">Protocol:</Label>
          <Input
            type="select"
            name="connector_used"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.connector_used)}
          >
          <option>N/A</option>
          <option value="HTTP">HTTP</option>
          {

            Gateways.map(m => (
              <option value={m.mqtt_client_id}>
                {m.mqtt_client_name}
              </option>
            ))
          }
          </Input>
        </FormGroup>
        <Button
          className="float-right btn-fill"
          disabled={ this.state.validate.sensorIDState === 'has-danger' }
          color="info"
          size="sm">Save
        </Button>
      </Form>

    );
  }
}

export default NewDeviceForm;
