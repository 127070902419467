import React from 'react';
import styled from "styled-components";

const Chip = ({ label }) =>  <ChipHeading>{label}</ChipHeading>;







const ChipHeading =  styled.h1`
  font-size: 0.7rem;
  background: linear-gradient(to right, #6190e8, #a7bfe8);
  color: #fff;
  padding: 0.3rem 0.5rem;
  margin: 0.3rem 0.5rem;
  border-radius: 5px;
  width: fit-content;
  text-transform: capitalize;
`;

export default Chip;
