import React from "react";
import { Button, Row,Col, Form, FormGroup, Input, Label } from "reactstrap";
import { Auth } from 'aws-amplify';

import axios from "axios";
import { config } from './Constants';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

var API_URL = config.url.API_URL+ "/device/";

class NewGatewayForm extends React.Component {
  state = {
    pk: 0, mqtt_client_name: "", mqtt_client_id: "",
    mqtt_client_username: "", mqtt_client_password: "", mqtt_client_broker: ""
  };

  async componentDidMount() {
    if (this.props.gateway) {
      const { pk, mqtt_client_name, mqtt_client_id,mqtt_client_username,
              mqtt_client_password, mqtt_client_broker } = this.props.gateway;
      this.setState({ pk, mqtt_client_name, mqtt_client_id,mqtt_client_username,
                      mqtt_client_password, mqtt_client_broker });
    }
    const userInfo = await Auth.currentUserInfo();
    this.setState({
         user: userInfo.attributes.email
       });

  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  createDevice = e => {
    e.preventDefault();
    axios.post(API_URL+"gateway_list/", this.state).then(() => {
      this.props.resetState();
      this.props.toggle();
    });
  };

  editDevice = e => {
    e.preventDefault();
    axios.put(API_URL+"gateway_update/" + this.state.pk, this.state).then(() => {
      this.props.resetState();
      this.props.toggle();
    });
  };

  defaultIfEmpty = value => {
    return value === "" ? "" : value;
  };

  render() {
    return (

      <Form onSubmit={this.props.gateway ? this.editDevice : this.createDevice}>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="mqtt_client_id">ID:</Label>
              <Input
                type="text"
                name="mqtt_client_id"
                onChange={this.onChange}
                value={this.defaultIfEmpty(this.state.mqtt_client_id)}
                maxLength="20"
                placeholder="Provide gateway ID..."
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="mqtt_client_name">Name:</Label>
                <Input
                  type="text"
                  name="mqtt_client_name"
                  onChange={this.onChange}
                  value={this.defaultIfEmpty(this.state.mqtt_client_name)}
                  maxLength="20"
                  placeholder="Provide gateway name..."
                />
              </FormGroup>
              </Col>

        </Row>
        <Row form>
          <Col md={6}>
          <FormGroup>
            <Label for="mqtt_client_username">Username:</Label>
            <Input
              type="text"
              name="mqtt_client_username"
              onChange={this.onChange}
              value={this.defaultIfEmpty(this.state.mqtt_client_username)}
              maxLength="20"
              placeholder="Provide gateway username..."
            />
          </FormGroup>
          </Col>
          <Col md={6}>
          <FormGroup>
            <Label for="mqtt_client_password">Password:</Label>
            <Input
              type="password"
              name="mqtt_client_password"
              onChange={this.onChange}
              value={this.defaultIfEmpty(this.state.mqtt_client_password)}
              placeholder="Provide gateway password..."
            />
          </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="mqtt_client_broker">Broker:</Label>
          <Input
            type="text"
            name="mqtt_client_broker"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.mqtt_client_broker)}
            maxLength="30"
            placeholder="Provide gateway address..."
          />
        </FormGroup>
        <Button
          className="float-right btn-fill"
          size="sm"
          color="info">
          Save
        </Button>
      </Form>

    );
  }
}

export default NewGatewayForm;
