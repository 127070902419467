
import React from "react";
import MapDevices from "./MapDevices";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow
} from "react-google-maps";
import { Spin } from "antd";

import { Auth } from 'aws-amplify';
import axios from "axios";



// reactstrap components
import { Row, Col, Card,  CardBody } from "reactstrap";


import PageHeader from "../components/PageHeader";



axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'


const API_URL = "/payload_parsing/";

class FullScreenMap extends React.Component {
  constructor(props){
    super(props);
  this.state = {
    isOpen: {},  // Hides or shows the InfoWindow
    isLoading: true,
    progress: [],
    data:[],
    path: [],
    username:'',
    devices:'',
  }
  this.timer = null;
  this.step = 0;
}

  getCoordinates(){
    this.state.path = this.state.data.map( device_location => {
      return device_location.path.map((coordinates, i, array) => {
        if (i === 0) {
          return { ...coordinates, distance: 0,
                      label:device_location.label,
                      title:device_location.title} // it begins here!
        }
        const { lat: lat1, lng: lng1 } = coordinates
        const latLong1 = new window.google.maps.LatLng(lat1, lng1)

        const { lat: lat2, lng: lng2 } = array[0]
        const latLong2 = new window.google.maps.LatLng(lat2, lng2)
        // in meters:
        const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
          latLong1,
          latLong2
        )
        return {...coordinates, distance}
      });

    })

  }

  async getPath ()  {

    await axios.get(API_URL+"location/", {params: { user_id: this.state.username }} )
    .then(res => {if (res.data!=this.state.path){
      this.setState({ data: res.data, isLoading:false});
      this.getCoordinates();
      this.moveObject();
    }});

  };

  async componentDidMount () {
    // this.timer = setInterval(() => this.getPath(), 50000);
    const userInfo = await Auth.currentUserInfo();

    this.setState({
         username: userInfo.attributes.email
       });
    this.getPath();
  }

  componentWillUnmount = () => {
    window.clearInterval(this.interval)
  }

  moveObject = () => {
    let device_path = []
    this.setState({ progress: [] });

    this.state.path.map( device_location =>{
      device_path = device_path.concat({label:device_location[0].label,
                                        title:device_location[0].title})
        device_location.map( location => {
          const nextLine = device_location[this.step+1]
          if (! nextLine) {
            device_path = device_path.concat(device_location[this.step])
            this.setState({ progress: this.state.progress.concat([device_path],) })
            device_path = []
            this.step=0
           return // it's the end!
                          }

      const lastLineLatLng = new window.google.maps.LatLng(
        location.lat,
        location.lng,
      )

      const nextLineLatLng = new window.google.maps.LatLng(
        device_location[this.step+1].lat,
        device_location[this.step+1].lng,
      )
    // distance of this line
      // const totalDistance = location.distance - location.distance
      // const percentage = (distance - lastLine.distance) / totalDistance
      const percentage = 1/1000
      const position = window.google.maps.geometry.spherical.interpolate(
        lastLineLatLng,
        nextLineLatLng,
        percentage
        )

      device_path = device_path.concat(position)

      if (this.step <= device_location.length){
        this.step++;
      }
    })
  })
  }

  onToggleOpen = (id)=> {
      let arr = {...this.state.isOpen};
      if (arr[id]===undefined){
        arr[id]=true} else {arr[id]=!arr[id]}

      this.setState({isOpen:arr})
  }

  render () {


  return (
    <>
        <GoogleMap
          defaultZoom={2}
          defaultCenter={{ lat:47.05 , lng: -5.9 }}
          >
          {this.state.isLoading? (
            <div  style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)', textAlign:'center'}}>
             <Spin tip="Loading..." size="large"/>
            </div>):(
           this.state.progress && this.state.progress.map( (path, index) => (
            <>
              <Polyline path={path.slice(1)} options={{ strokeColor: "#FF0000 "}} />
                <Marker key={index}

                        onClick={() => this.onToggleOpen(index)}
                        position={path[path.length-1]}
                        label={path[0].label}
                        title={path[0].title}>
                { this.state.isOpen[index] &&
                <InfoWindow
                  key={index}
                  onCloseClick={() => this.onToggleOpen(index)}
                >

                <>
                <p className="text-muted">Current Info:</p>
                <p className="text-muted">
                  {path[path.length-1].val}
                </p>
                </>
            </InfoWindow>
            }
            </Marker>
            </>
          ))
        )}
        </GoogleMap>

        </>
      )
    }
  }



const MapWrapper = withScriptjs(withGoogleMap(FullScreenMap));

export default () => (
  <>
    <div className="content">
    <PageHeader/>
    <Row>
      <Col xs={12}>
        <Card>
          <CardBody>
            <div
              id="map"
              className="map"
              style={{ position: "relative", overflow: "hidden" }}
            >
              <MapWrapper
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
      </Row>
      <Row>
        <Col>
          <MapDevices
          />
        </Col>
      </Row>
    </div>
</>
);
