import React from 'react';
// import sections
import Hero from '../components/HomePage/sections/Hero';
import Blog from '../views/Blog';
// import Testimonial from '../components/HomePage/sections/Testimonial';
// import Cta from '../components/HomePage/sections/Cta';
import Main from '../components/Layout/Main';
const BlogsPage = () => {

  return (
    <>
      <Main title={"NexGen IoT — home"} description={"Remote control your IoT devices with our P2P IoT connectivity platform. Direct and secure connections with low latency and maximum privacy."}/>
      <Hero className="illustration-section-01" />
      <Blog />
    </>
  );
}

export default BlogsPage;
