import React, { useState, useEffect } from "react";
import { Auth } from 'aws-amplify';
import {
  Card,
  CardHeader,
  CardTitle,
  Row,
  Col,} from "reactstrap";

import { IconContext } from "react-icons";
import { BsExclamationCircleFill, BsCheckAll, BsInfoCircleFill, BsHeartFill } from "react-icons/bs";
import { BiVector } from "react-icons/bi";
import { GoRadioTower } from "react-icons/go";

import styled from 'styled-components';
import axios from "axios";

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const StyledRow = styled(Row)`
  padding: 15px 15px 0px 15px;
`

const ButtonsCol = styled(Col)`
  text-align: right;
`

const API_URL_FETCH = "/device/summary/"
const PageHeader = ({ title, button, noBorder }) => {
    const [header, setHeader] = useState([]);
    const [user, setUser] = useState(null);




  useEffect( () => {

    const getUser = async () => {
          const userInfo = await Auth.currentUserInfo().then(result =>{
            setUser(result);
            getData(result);
          });
          };

    if(!user){
      getUser();
    } else{
      const timer = setInterval(() => getData(user), 5000);
    }
    const getData = async (userInfo) => {
    const result = await axios.get(API_URL_FETCH, {params: { user_id: userInfo.attributes.email } })
                                .then(result =>  {
                                  setHeader(result.data);
                                    });
          };

    }, [user]);

return(
  <>
  <StyledRow>
  <Col sm="3" lg="3">
    <Card className="card-chart text-center" style={{height:100}}>
      <CardHeader>
        <CardTitle tag="h6">Notifications</CardTitle>
        <CardTitle tag="h3">
          <i className="text-info" />{" "}

          {header.notifications===0?
              (<IconContext.Provider value={{ color: "gray", size: "1.1em"}}>
                <BsInfoCircleFill/> {header.notifications}
               </IconContext.Provider>):
              (<IconContext.Provider value={{ color: "green", size: "1.1em"}}>
                  <BsInfoCircleFill/> {header.notifications}
               </IconContext.Provider>)
          }
        </CardTitle>
      </CardHeader>
    </Card>
  </Col>
  <Col sm="2" lg="2">
    <Card className="card-chart text-center" style={{height:100}}>
      <CardHeader>
      <CardTitle tag="h6">Alerts</CardTitle>
        <CardTitle tag="h3">
          <i className="text-info" />{" "}

          {header.alerts===0?
            (  <IconContext.Provider value={{ color: "green", size: "2em"}}>
                <BsCheckAll/> {header.alerts}
              </IconContext.Provider>):
              (<IconContext.Provider value={{ color: "red", size: "1.1em"}}>
                  <BsExclamationCircleFill/> {header.alerts}
                </IconContext.Provider>)
          }
        </CardTitle>
      </CardHeader>
    </Card>
  </Col>

  <Col sm="2" lg="2">
    <Card className="card-chart text-center" style={{height:100}}>
      <CardHeader>
        <CardTitle tag="h6">Devices</CardTitle>
        <CardTitle tag="h3">
          <i className="text-primary" />{" "}
          {header.devices===0?
              (<IconContext.Provider value={{ color: "gray", size: "1.1em"}}>
                <BiVector/> {header.devices}
               </IconContext.Provider>):
              (<IconContext.Provider value={{ color: "green", size: "1.1em"}}>
                  <BiVector/> {header.devices}
               </IconContext.Provider>)
          }
        </CardTitle>
      </CardHeader>
    </Card>
  </Col>
  <Col sm="2" lg="2">
    <Card className="card-chart text-center" style={{height:100}}>
      <CardHeader>
        <CardTitle tag="h6">Gateways</CardTitle>

        <CardTitle tag="h3">
          <i className="text-primary" />{" "}
          {header.gateways===0?
              (<IconContext.Provider value={{ color: "gray", size: "1.1em"}}>
                <GoRadioTower/> {header.gateways}
               </IconContext.Provider>):
              (<IconContext.Provider value={{ color: "green", size: "1.1em"}}>
                  <GoRadioTower/> {header.gateways}
               </IconContext.Provider>)
          }
        </CardTitle>
      </CardHeader>
    </Card>
  </Col>
  <Col sm="3" lg="3">
    <Card className="card-chart text-center" style={{height:100}}>
      <CardHeader>
        <CardTitle tag="h6">Health Status</CardTitle>
        <CardTitle tag="h3">
          <i className="text-success" />
          {header.status===0?
              (<IconContext.Provider value={{ color: "red", size: "1.1em"}}>
                <BsHeartFill/> {header.status}
               </IconContext.Provider>):
              (<IconContext.Provider value={{ color: "green", size: "1.1em"}}>
                  <BsHeartFill/> {header.status} OK
               </IconContext.Provider>)
          }
        </CardTitle>
      </CardHeader>
    </Card>
  </Col>
  </StyledRow>
  </>
);
}

export default PageHeader;
