import React from 'react';
import ReactDOM from 'react-dom';
import {createBrowserHistory} from "history";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import AppRoute from "./components/HomePage/utils/AppRoute";
import DashboardPage from "./pages/DashboardPage.jsx";
import HomePage from "./pages/HomePage.jsx";
import AboutPage from "./pages/AboutPage.jsx";
import ContactPage from "./pages/ContactPage.jsx";
import PricingPage from "./pages/PricingPage.jsx";
import BlogsPage from "./pages/BlogsPage.jsx";
import BlogPage from"./pages/BlogPage.jsx";
// import BlogPage from "./pages/BlogPage.jsx";
import "./assets/scss/black-dashboard-react.scss";
import "./assets/demo/demo.css";
import "./assets/css/nucleo-icons.css";

// Layouts
import LayoutDefault from "./components/HomePage/LayoutDefault";
const hist = createBrowserHistory({forceRefresh:true});

ReactDOM.render(


  <React.StrictMode>

    <Router history={hist}>
      <Switch>
          <AppRoute exact path="/" component={HomePage} layout={LayoutDefault} />
          <AppRoute exact path="/about-us/" component={AboutPage} layout={LayoutDefault}/>
          <AppRoute exact path="/contact/" component={ContactPage} layout={LayoutDefault}/>
          <AppRoute exact path="/pricing/" component={PricingPage} layout={LayoutDefault}/>
          <AppRoute exact path="/blogs/" component={BlogsPage} layout={LayoutDefault} />
          <AppRoute exact path="/blogs/:pk" component={BlogPage} layout={LayoutDefault}/>} />
        <App>
          <Route path="/dashboard/" render={props => <DashboardPage {...props} />} />
          <Redirect from="/login/" to="/dashboard/main/" />
        </App>
      </Switch>

    </Router>

  </React.StrictMode>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
