// Constants.js

const prod = {
  url:{
    API_URL:'https://serene-everglades-00422.herokuapp.com'
  }
};

const dev = {
  url:{
    API_URL:'http://localhost:3000'
  }
};

export const config = process.env.NODE_ENV === 'development'? dev:prod;
