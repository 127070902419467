import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import GatewayList from "../components/GatewayList";
import NewGatewayModal from "../components/NewGatewayModal";
import StoreKey from "../components/NewStoreKey";
import { Auth } from 'aws-amplify';
import axios from "axios";
import { config } from '../components/Constants'


axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
var API_URL = config.url.API_URL+ '/device/';

class Home extends Component {
  state = {
    gateways: [],
    storedata:'',
    loading: true,
    username:''
  };

  async componentDidMount() {
    const userInfo = await Auth.currentUserInfo();
    this.setState({
         username: userInfo.attributes.email
       });
    this.resetState();
  }

  getGateways = () => {
    axios.get(API_URL+"gateway_list/", {params: { user_id: this.state.username }} )
    .then(res => this.setState({ gateways: res.data }));
    axios.get(API_URL+"storekey_list/", {params: { user_id: this.state.username }} )
    .then(res => this.setState({ storedata: res.data, loading:false }));

  };

  generateKey=() => {
    axios.get(API_URL+"generate_key/", {params: { user_id: this.state.username }} )
    .then(res => this.setState({ storekey: res.data, loading:false }));
    this.getGateways();
  }

  resetState = () => {
    this.getGateways();
  };

  render() {

    return (
      <div className="content">
      <Container style={{ marginTop: "20px" }}>
        <Row>
          <Col>
            <GatewayList
              loading={this.state.loading}
              gateways={this.state.gateways}
              resetState={this.resetState}
            />
            <NewGatewayModal
              create={true}
              resetState={this.resetState}
              loading={this.state.loading}
              />
          </Col>
        </Row>


        <Row>
        <Col>
        <StoreKey
          loading={this.state.loading}
          generate={this.generateKey}
          storedata={this.state.storedata}
          loading={this.state.loading}
        />
          </Col>
        </Row>
      </Container>
      </div>
    );
  }
}

export default Home;
