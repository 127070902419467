import React from "react";

// nodejs library that concatenates classes

import { Spin, Alert} from "antd";

// reactstrap components
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { Icon } from "@ant-design/compatible";
import { GET_DASHBOARD_ITEMS } from "../graphql/queries";
import ChartRenderer from "../components/ChartRenderer";
import Dashboard from "../components/Dashboard";
import DashboardItem from "../components/DashboardItem";
import PageHeader from "../components/PageHeader";


const deserializeItem = i => ({
  ...i,
  layout: JSON.parse(i.layout) || {},
  vizState: JSON.parse(i.vizState)
});

const defaultLayout = i => ({
  x: i.layout.x || 0,
  y: i.layout.y || 0,
  w: i.layout.w || 4,
  h: i.layout.h || 8,
  minW: 4,
  minH: 8
});


const DashboardLogic = () => {
  const { loading, error, data } = useQuery(GET_DASHBOARD_ITEMS);


  if (loading) {
    return (
      <div className="content">
        <div  style={{
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)', textAlign:'center'}}>
          <Spin tip="Loading..." size="large"/>
        </div>
     </div>);
  }



  if (error) {
    return (
      <Alert
        message="Error occured while loading your query"
        description={error.toString()}
        type="error"
      />
    );
  }

  const dashboardItem = item => (
    <div key={item.id} data-grid={defaultLayout(item)}>
    <DashboardItem key={item.id} itemId={item.id} title={item.name}>
        <ChartRenderer vizState={item.vizState} />
    </DashboardItem>
    </div>
  );


  const Empty = () => (
    <div
      style={{
        textAlign: "center",
        padding: 12
      }}
    >
      <h2>There are no charts on this dashboard.</h2>
      <Link to="/dashboard/ChartSettings">
        <Button type="primary" size="large" icon={<Icon type="plus" />}>
          Add chart
        </Button>
      </Link>
    </div>
  );

  return(

    !data || data.listDashboardItems.items.length ? (
    <div className="content">
    <PageHeader/>
     <Dashboard dashboardItems={data && data.listDashboardItems.items}>
       {data && data.listDashboardItems.items.map(deserializeItem).map(dashboardItem)}
     </Dashboard>
    </div>
  ) :
  <div className="content">
  <Empty />
  </div>
)};

export default DashboardLogic;
