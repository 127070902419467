import React from "react";

// reactstrap components
import { Route, Switch, Redirect } from "react-router-dom";


import PerfectScrollbar from "perfect-scrollbar";



// core components
import Main from '../components/Layout/Main';

import Sidebar from "../components/Sidebar/Sidebar.jsx";

import Footer from "../components/Footer/Footer";
import routes from "../routes";

import logo from "../assets/img/react-logo.png";

var ps;

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "blue",
      username: "",
      sidebarOpened:
        document.documentElement.className.indexOf("nav-open") !== -1
    };

  }


  async componentDidMount() {




    if (navigator.platform.indexOf("Win") > -1) {
  
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/dashboard") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null
      }
    });
  };

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };
  //
  // handleBgClick = color => {
  //   this.setState({ backgroundColor: color });
  // };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

render(){
  return (
    <>
    <div className="wrapper">
    <Main title={"NexGen IoT — dashboard"} description={"IoT dashboard"}/>
    <Sidebar
    {...this.props}
    routes={routes}
    bgColor={this.state.backgroundColor}
    logo={{
      outterLink: "",
      text: "NexGen IOT",
      imgSrc: logo
    }}
    toggleSidebar={this.toggleSidebar}
    />
    <div
      className="main-panel"
      ref="mainPanel"
      data={this.state.backgroundColor}
      >

    <Switch>
      {this.getRoutes(routes)}
      <Redirect from="*" to="/dashboard/main/"/>
    </Switch>
    <Footer fluid />
    </div>
    </div>
    </>
    );
  }
}
export default DashboardPage;
