import styled from "styled-components";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { Pagination } from "../components/Pagination";
import { BlogList } from "../components/BlogList";
import { Spin } from "antd";
import { config } from '../components/Constants';

const ITENS_PER_PAGE = 12;
const TOTAL_API_LIMIT = 20;

var API_URL = config.url.API_URL+ '/core/';


export function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  function onChangeSearch(event) {
    setSearch(event.target.value);
  }

  const debouncedSearch = useDebounce(search, 500);

  const hasSearch = !!search.trim("");



  async function getBlogs() {
    const response = await axios.get(
      API_URL+"blogs/",
    );
    setBlogs(response.data);
    setLoading(false);
  }

  useEffect(() => {
    getBlogs();
  }, [currentPage]);

  function onPageChange(page) {
    setCurrentPage(page);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    getBlogs();
  }, [debouncedSearch]);

  return (
    <Wrapper>
      <BlogList blogs={blogs} />
      {blogs && blogs.length > 0 ? (
        <Pagination
          totalCountOfRegisters={TOTAL_API_LIMIT}
          currentPage={currentPage}
          onPageChange={onPageChange}
          registerPerPage={ITENS_PER_PAGE}
        />
      ):(
        <div style={{textAlign:'center'}}>
         <Spin tip="Loading..." size="large"/>
        </div>)}
    </Wrapper>
  );
}


const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;

export default Blog;
