import React from "react";

import { withRouter} from "react-router";
import './App.css';
import { Layout } from "antd";
import "antd/dist/antd.css";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import { Rehydrated } from "aws-appsync-react";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { ApolloProvider } from "react-apollo";
import { withAuthenticator } from 'aws-amplify-react';
import Amplify, {Auth, Hub} from 'aws-amplify';
import aws_exports from './aws-exports.js';
import AuthenticationForm from "./views/Authentication.jsx";
require('dotenv').config();
Amplify.configure(aws_exports);

const client = new AWSAppSyncClient(
  {
    disableOffline: true,
    url: aws_exports.aws_appsync_graphqlEndpoint,
    region: aws_exports.aws_appsync_region,
    auth: {
         type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
         jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
       },
  },
  { cache: new InMemoryCache() }
);


// Added with JWT
Hub.listen('auth', (data) => {
  if (data.payload.event === 'signOut') {
    client.resetStore();
  }
});



const AppLayout = ({ children }) => (
  <Layout style={{ height: "100%" }}>
    <Layout.Content>{children}</Layout.Content>
  </Layout>
);




const App = withRouter(({ location, children }) => (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <Rehydrated>
          <AppLayout>{children}</AppLayout>
        </Rehydrated>
      </ApolloHooksProvider>
    </ApolloProvider>
));

export default withAuthenticator(App, false, [<AuthenticationForm/>]);
